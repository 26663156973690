import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';
import fbConfig from '../configs/firebase.config';

export const firebaseEventsApp = firebase.initializeApp(
  fbConfig.eventsDbConfig
);
export const firebaseOperationsApp = firebase.initializeApp(
  fbConfig.operationsDbConfig,
  'operations'
);
export const firebaseMasterApp = firebase.initializeApp(
  fbConfig.masterDbConfig,
  'master'
);

//firebaseEventsApp.firestore().settings({ host: "localhost:8080", ssl: false });
// firebaseOperationsApp.firestore().settings({ host: "localhost:8080", ssl: false });
export const firebaseAuth = firebaseOperationsApp.auth();
export const firebaseDb = firebaseEventsApp.database();
export const firestoreEventsDb = firebaseEventsApp.firestore();
export const firestoreOperationsDb = firebaseOperationsApp.firestore();
export const firestoreMasterDb = firebaseMasterApp.firestore();
export const firebaseAdminAuth = firebaseMasterApp.auth();
export const firebaseStorage = firebaseOperationsApp.storage();

// if(firebase.messaging.isSupported()) {
//   export const operationsMessaging = firebaseOperationsApp.messaging();
// }
export const operationsDb = firestoreOperationsDb;
export const masterDb = firestoreMasterDb;

export const firebaseMaster = firebase;
