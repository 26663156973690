import moment from 'moment';

import axios from 'axios';

import appConstants from '../appConstants';
import {
  createUserWithEmailAndPassword,
  registerMemberEvent,
  sendSetPasswordEmail,
} from '../store/actions';
import { documentTitleFetch } from '../APIs/Authentication';

const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePassword = (password) => {
  var re = /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
  return re.test(password);
};

function getMonthName(monthNumber) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Check if the month number is valid
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return 'Invalid month number';
  }
}

const makeid = async (length, characters) => {
  var result = '';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isAdminRole = (role) => role === 'admin';

const createRecurringTasks = (
  frequencyCount,
  frequencyPeriod,
  durationCount,
  durationPeriod
) => {
  console.log('frequencyCount', frequencyCount);
  console.log('frequencyPeriod', frequencyPeriod);
  console.log('durationCount', durationCount);
  console.log('durationPeriod', durationPeriod);
  let today = new Date().getHours();
  let total,
    dueDates = [],
    duration = '',
    frequency = '',
    fCount = '',
    fDuration = '';
  let startDate = today > 18 ? moment().add(1, 'days') : moment();

  if (durationPeriod == 'day') {
    if (frequencyPeriod == 'day') {
      total = Number(frequencyCount) * Number(durationCount);
      while (dueDates.length < total) {
        let slotDuration = 24 / Number(frequencyCount);
        let day;
        if (
          moment(moment(startDate).format(appConstants.DATE_FORMAT)).isSame(
            moment().format(appConstants.DATE_FORMAT)
          )
        ) {
          day = moment.range(moment(startDate), moment(startDate).endOf('day'));
        } else {
          day = moment.range(
            moment(startDate).startOf('day'),
            moment(startDate).endOf('day')
          );
        }

        let slots = Array.from(day.by('hours', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'days');
      }
    } else if (frequencyPeriod == 'week') {
      let endDate = moment(startDate).add(Number(durationCount), 'days');
      let numberOfWeeks = Math.round(
        (endDate - startDate) / (7 * 24 * 60 * 60 * 1000)
      );
      total = Number(frequencyCount) * Number(numberOfWeeks);
      while (dueDates.length < total) {
        let slotDuration = 7 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'weeks');
      }
    } else if (frequencyPeriod == 'month') {
      let endDate = moment(startDate).add(Number(durationCount), 'days');
      let numberOfMonths = endDate.diff(startDate, 'months');
      total = Number(frequencyCount) * numberOfMonths;
      while (dueDates.length < total) {
        let slotDuration = 30 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'months');
      }
    }
  } else if (durationPeriod == 'week') {
    if (frequencyPeriod == 'day') {
      let endDate = moment(startDate).add(Number(durationCount), 'weeks');
      let numberOfDays = endDate.diff(startDate, 'days');
      total = Number(frequencyCount) * Number(numberOfDays);
      while (dueDates.length < total) {
        let slotDuration = 24 / Number(frequencyCount);
        let day;
        if (
          moment(moment(startDate).format(appConstants.DATE_FORMAT)).isSame(
            moment().format(appConstants.DATE_FORMAT)
          )
        ) {
          day = moment.range(moment(startDate), moment(startDate).endOf('day'));
        } else {
          day = moment.range(
            moment(startDate).startOf('day'),
            moment(startDate).endOf('day')
          );
        }

        let slots = Array.from(day.by('hours', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'days');
      }
    } else if (frequencyPeriod == 'week') {
      let endDate = moment(startDate).add(Number(durationCount), 'weeks');
      let numberOfWeeks = Math.round(
        (endDate - startDate) / (7 * 24 * 60 * 60 * 1000)
      );
      total = Number(frequencyCount) * Number(numberOfWeeks);
      while (dueDates.length < total) {
        let slotDuration = 7 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'weeks');
      }
    } else if (frequencyPeriod == 'month') {
      let endDate = moment(startDate).add(Number(durationCount), 'weeks');
      let numberOfMonths = endDate.diff(startDate, 'months');
      total = Number(frequencyCount) * Number(numberOfMonths);
      while (dueDates.length < total) {
        let slotDuration = 30 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'months');
      }
    }
  } else if (durationPeriod == 'month') {
    if (frequencyPeriod == 'day') {
      let enddate = moment(startDate).add(Number(durationCount), 'months');
      let numberOfDays = enddate.diff(startDate, 'days');
      total = Number(frequencyCount) * numberOfDays;
      while (dueDates.length < total) {
        let slotDuration = 24 / Number(frequencyCount);
        let day;
        if (
          moment(moment(startDate).format(appConstants.DATE_FORMAT)).isSame(
            moment().format(appConstants.DATE_FORMAT)
          )
        ) {
          day = moment.range(moment(startDate), moment(startDate).endOf('day'));
        } else {
          day = moment.range(
            moment(startDate).startOf('day'),
            moment(startDate).endOf('day')
          );
        }

        let slots = Array.from(day.by('hours', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'days');
      }
    } else if (frequencyPeriod == 'week') {
      let endDate = moment(startDate).add(Number(durationCount), 'months');
      let numberOfWeeks = endDate.diff(startDate, 'weeks');
      total = Number(frequencyCount) * Number(numberOfWeeks);
      while (dueDates.length < total) {
        let slotDuration = 7 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'weeks');
      }
    } else if (frequencyPeriod == 'month') {
      let endDate = moment(startDate).add(Number(durationCount), 'months');
      let numberOfMonths = endDate.diff(startDate, 'months');
      total = Number(frequencyCount) * Number(numberOfMonths);
      while (dueDates.length < total) {
        let slotDuration = 30 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'months');
      }
    }
  } else if (durationPeriod == 'year') {
    if (frequencyPeriod == 'day') {
      let enddate = moment(startDate).add(Number(durationCount), 'years');
      let numberOfDays = enddate.diff(startDate, 'days');
      total = Number(frequencyCount) * numberOfDays;
      while (dueDates.length < total) {
        let slotDuration = 24 / Number(frequencyCount);
        let day;
        if (
          moment(moment(startDate).format(appConstants.DATE_FORMAT)).isSame(
            moment().format(appConstants.DATE_FORMAT)
          )
        ) {
          day = moment.range(moment(startDate), moment(startDate).endOf('day'));
        } else {
          day = moment.range(
            moment(startDate).startOf('day'),
            moment(startDate).endOf('day')
          );
        }

        let slots = Array.from(day.by('hours', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'days');
      }
    } else if (frequencyPeriod == 'week') {
      let endDate = moment(startDate).add(Number(durationCount), 'years');
      let numberOfWeeks = endDate.diff(startDate, 'weeks');
      total = Number(frequencyCount) * Number(numberOfWeeks);
      while (dueDates.length < total) {
        let slotDuration = 7 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'weeks');
      }
    } else if (frequencyPeriod == 'month') {
      let endDate = moment(startDate).add(Number(durationCount), 'years');
      let numberOfMonths = endDate.diff(startDate, 'months');
      total = Number(frequencyCount) * Number(numberOfMonths);
      while (dueDates.length < total) {
        let slotDuration = 30 / Number(frequencyCount);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(1, 'months');
      }
    } else if (frequencyPeriod == 'halfYear') {
      let endDate = moment(startDate).add(Number(durationCount), 'years');
      let numberOfMonths = endDate.diff(startDate, 'months') / 6;
      total = Number(frequencyCount) * Number(numberOfMonths);
      console.log('numberOfMonths', numberOfMonths);
      console.log('total', total);
      console.log('dueDates', dueDates);
      while (dueDates.length < total) {
        let slotDuration = Math.round(365 / 2) / Number(frequencyCount);
        console.log('slotDuration', slotDuration);
        const day = moment.range(moment(startDate), moment(endDate));
        let slots = Array.from(day.by('days', { step: slotDuration }));
        console.log('slots', slots);
        slots &&
          slots.map((val) => {
            dueDates.push(moment(val).format(appConstants.DATE_FORMAT));
          });
        startDate = moment(startDate).add(6, 'months');
      }
    }
  }

  duration = `Due By ${moment(dueDates[dueDates.length - 1]).format(
    appConstants.DURATION_TIME_FORMAT
  )}`;

  if (frequencyCount == 1) fCount = 'Once';
  else fCount = `${frequencyCount} times`;

  if (frequencyPeriod == 'day') fDuration = 'Daily';
  else if (frequencyPeriod == 'week') fDuration = 'Weekly';
  else if (frequencyPeriod == 'month') fDuration = 'Monthly';
  else if (frequencyPeriod == 'halfYear') fDuration = 'every 6 month';

  frequency = `${fCount} ${fDuration}`;
  return { dueDates, total, frequency, duration };
};

// let resCreate = createRecurringTasks("1", "halfYear", "1", "year")
// console.log("resCreate", resCreate)
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.conditionName == current.conditionName;
      }).length == 1
    );
  };
}

const addClinician = async (data) => {
  return await new Promise(async (resolve, reject) => {
    let { clinicianName, clinicianEmail, clinicId } = data;
    clinicianName = clinicianName.trim();
    clinicianName = clinicianName.split(' ');
    let newUser = await createUserWithEmailAndPassword(
      clinicianEmail,
      appConstants.defaultPassword
    );
    let firstName = clinicianName && clinicianName[0] ? clinicianName[0] : '';
    clinicianName.shift();

    let lastName = clinicianName.join(' ');

    await newUser.user.sendEmailVerification();
    let eventId = await registerMemberEvent({
      userId: newUser.user.uid,
      role: appConstants.roles.TEAMMEMBER,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: '',
      email: clinicianEmail,
      fieldOfMedicine: '',
      clinicId,
      profilePicUrl: '',
      isClinicAdmin: false,
      isDeleted: false,
      isProfileSet: false,
    });

    if (eventId) {
      await sendSetPasswordEmail(clinicianEmail, newUser.user.uid);
      resolve(newUser.user.uid);
    }
  });
};

const convertToLocalString = (number = 0) => {
  return number.toLocaleString();
};

const asyncForEachPromise = async (dataSnapshot, childFunction) => {
  const toWait = [];
  dataSnapshot.forEach((childSnapshot) => {
    toWait.push(childFunction(childSnapshot));
  });
  await Promise.all(toWait);
};

function getCurrentWeek() {
  var currentDate = moment();

  var weekStart = currentDate.clone().startOf('isoWeek');
  var weekEnd = currentDate.clone().endOf('isoWeek');

  var days = [];

  for (var i = 0; i <= 6; i++) {
    days.push(
      moment(weekStart).add(i, 'days').format(appConstants.DATE_FORMAT)
    );
  }
  return days;
}

function getBool(val) {
  return !!JSON.parse(String(val).toLowerCase());
}

function calculateDataExhaust(clinicDetails) {
  let isDataExhaust = false;
  if (
    clinicDetails &&
    clinicDetails.subscriptionPlan &&
    clinicDetails.subscriptionPlan ===
      appConstants.subscriptionPlan.free.name &&
    clinicDetails.teleHeathVideoCallTimeExhaust > 1 * 3600
  ) {
    isDataExhaust = true;
  } else if (
    clinicDetails &&
    clinicDetails.subscriptionPlan &&
    clinicDetails.subscriptionPlan ===
      appConstants.subscriptionPlan.basic.name &&
    clinicDetails.teleHeathVideoCallTimeExhaust > 5 * 3600
  ) {
    isDataExhaust = true;
  } else if (
    clinicDetails &&
    clinicDetails.subscriptionPlan &&
    clinicDetails.subscriptionPlan ===
      appConstants.subscriptionPlan.premium.name &&
    clinicDetails.teleHeathVideoCallTimeExhaust > 20 * 3600
  ) {
    isDataExhaust = true;
  }
  return isDataExhaust;
}

function secondsToHm(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  return hDisplay + mDisplay;
}

function secondsToMs(d) {
  d = Number(d);
  var m = Math.floor(d / 60);
  var s = Math.floor(d % 60);

  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return mDisplay + sDisplay;
}
function secondsToMsCall(d) {
  d = Number(d);
  var m = Math.floor(d / 60);
  var s = Math.floor(d % 60);

  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return mDisplay;
}

function capitalizeFirstLetter(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
function haveVal(valueData) {
  if (
    valueData !== undefined &&
    valueData !== 'undefined' &&
    valueData !== '' &&
    valueData !== null &&
    valueData !== 'null'
  ) {
    return true;
  } else {
    return false;
  }
}

function extractFileName(url) {
  console.log('url', url);
  const urlObject = new URL(url);
  const pathArray = urlObject.pathname.split('/');
  const fileNameWithExtension = pathArray.pop(); // Get the last part which is the file name with extension
  return fileNameWithExtension;
}

function arraysAreEqual(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

const getFileExtension = (filename) => {
  const parts = filename.split('.');
  if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
    return ''; // No extension found
  }
  return parts.pop();
};

const documentTitleBasedOnExt = async (url) => {
  try {
    const parts = url.split('/');
    const fileNameWithExtension = parts[parts.length - 1];
    const ext = getFileExtension(fileNameWithExtension);

    if (ext !== '') {
      const fileName = fileNameWithExtension.slice(0, -(ext.length + 1));
      return fileName;
    } else {
      const data = await videoTitle(url);
      return data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const videoTitle = async (url) => {
  try {
    if (url) {
      const response = await documentTitleFetch(url);
      if (response.status === 200) {
        const title = response.data.title;
        return title;
      }
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

function extractFilenameFromUrl(url) {
  if (!url) {
    return null;
  }

  try {
    const fileUrl = new URL(url);
    const filename = fileUrl.pathname.split('/').pop();
    return decodeURIComponent(filename);
  } catch (error) {
    console.error('Error extracting filename from URL:', error);
    return null;
  }
}

export {
  arraysAreEqual,
  getMonthName,
  validateEmail,
  makeid,
  extractFilenameFromUrl,
  validatePassword,
  createRecurringTasks,
  addClinician,
  asyncForEach,
  convertToLocalString,
  asyncForEachPromise,
  getCurrentWeek,
  getBool,
  calculateDataExhaust,
  secondsToHm,
  secondsToMs,
  secondsToMsCall,
  capitalizeFirstLetter,
  haveVal,
  extractFileName,
  documentTitleBasedOnExt,
};
