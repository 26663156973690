let badges = [
    {
        "name": "Signup",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge-5grey.png?alt=media&token=847bb0c7-05fa-4473-acde-1392bc1378a9",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge-5.png?alt=media&token=fd956e57-3ece-49c5-a4c7-e10c8e142a8a",
        pointsRequired: 0,
        priority: 1
    },
    {
        "name": "Registered",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge36grey.png?alt=media&token=0b1a41e5-2e71-48d7-b65d-eb0989bd9ca5",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge36.png?alt=media&token=7dbac719-af68-4a5d-9fdd-8355dc5f5a68",
        pointsRequired: 0,
        priority: 2
    },
    {
        "name": "Verified",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge29grey.png?alt=media&token=49e2210e-0d53-4673-8786-cdcd9bdd7d72",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge29.png?alt=media&token=578f2f40-d6df-4a13-a4dd-e5c89b2eafca",
        pointsRequired: 0,
        priority: 3
    },
    {
        "name": "First Task",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge13grey.png?alt=media&token=d24326c4-e499-43e2-8266-23f460096e7b",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge13.png?alt=media&token=67630f83-67e7-4239-ac44-921ff8a9f770",
        pointsRequired: 0,
        priority: 4,
        isFirstTask: true
    },
    {
        "name": "Achiever",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge25grey.png?alt=media&token=f6b319e3-8439-4a62-ae72-a053b4669026",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge25.png?alt=media&token=c27d3c2c-4aac-4f09-9eea-b0709eedd3ed",
        pointsRequired: 50,
        priority: 5
    },
    {
        "name": "Badges 1",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge-6.png?alt=media&token=c0e12fc4-479e-4db1-b204-39d3508769c6",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge-6grey.png?alt=media&token=8afb3534-1f7a-4b74-8949-558abe6059ae",
        pointsRequired: 100,
        priority: 6
    },
    {
        "name": "Badges 2",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge33grey.png?alt=media&token=eba4903f-860a-4873-b532-3cb731e9ab5c",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge33.png?alt=media&token=995664e0-933c-4966-a66b-709c6a49d430",
        pointsRequired: 500,
        priority: 7
    }, {
        "name": "Badges 3",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge23grey.png?alt=media&token=07dd5040-4925-4ec6-9e0a-e8730e40c7a6",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge23.png?alt=media&token=e8a6caf3-fc0a-4302-a1cf-9252de3aa3d2",
        pointsRequired: 1000,
        priority: 5
    }, {
        "name": "Badges 4",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge10grey.png?alt=media&token=5783f369-3b87-4050-b09a-c308b7144dda",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge10.png?alt=media&token=a5c539de-7622-4ad7-94f3-8fdf1a627e96",
        pointsRequired: 1500,
        priority: 8
    }, {
        "name": "Badges 5",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge14grey.png?alt=media&token=8b78f56a-a943-45e6-875d-0ad916ab048e",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge14.png?alt=media&token=0d804f81-cfea-458f-bcf6-51d5aed8cf2e",
        pointsRequired: 2000,
        priority: 9
    }, {
        "name": "Badges 6",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge24grey.png?alt=media&token=2d861eb9-143e-4308-a5f8-2d7e0b4e81a3",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge24.png?alt=media&token=d24616f9-0cea-4b5c-b565-b22b3a634ac0",
        pointsRequired: 2500,
        priority: 10
    }, {
        "name": "Badges 7",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge2grey.png?alt=media&token=95989666-651f-4925-be53-959de6c54fb2",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge2.png?alt=media&token=b1ab99fc-2f4d-4799-86cb-9baf8813a0df",
        pointsRequired: 3000,
        priority: 11
    }, {
        "name": "Badges 8",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge18grey.png?alt=media&token=84684404-ae0f-4295-b094-8c298adaf34f",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge18.png?alt=media&token=b3d6017a-4a62-4f99-aba9-6cc78a04aee7",
        pointsRequired: 3500,
        priority: 12
    }, {
        "name": "Badges 9",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge11grey.png?alt=media&token=57697962-9e02-43bf-8a20-29b22e8fcfba",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge11.png?alt=media&token=7bb03f44-ab2f-4422-9dd2-00a9c7c3c38e",
        pointsRequired: 4000,
        priority: 13
    }, {
        "name": "Badges 10",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge1grey.png?alt=media&token=60feba5f-4614-4bb2-8a57-feec678375b1",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge1.png?alt=media&token=0e760cbb-e1b5-42e8-b233-77f79fea99bb",
        pointsRequired: 4500,
        priority: 14
    }, {
        "name": "Badges 11",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge5grey.png?alt=media&token=1c5edf6d-25a6-4b82-929c-94c1f9b6315f",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge5.png?alt=media&token=4c4f32fb-5814-43b5-995c-58832eb35bd4",
        pointsRequired: 5000,
        priority: 15
    }, {
        "name": "Badges 12",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge3grey.png?alt=media&token=28a177d3-27d9-4f65-ae4a-9bf288e559ec",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge3.png?alt=media&token=7788c466-5fc0-4269-9345-2d7ca40abead",
        pointsRequired: 5500,
        priority: 16
    }, {
        "name": "Badges 13",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge4grey.png?alt=media&token=493cd4ef-4d41-49a5-a845-3802a55fb640",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge4.png?alt=media&token=cabb8791-8960-467a-a0a4-3c292e7b8416",
        pointsRequired: 6000,
        priority: 17
    }, {
        "name": "Badges 14",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge8grey.png?alt=media&token=7fe242b9-782b-4bd5-b72e-1b635460679d",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge8.png?alt=media&token=1bdb0176-11a3-4b98-91bf-ef4d56b5d402",
        pointsRequired: 6500,
        priority: 18
    }, {
        "name": "Badges 15",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge7grey.png?alt=media&token=d561a8fb-7294-460e-b745-32b6375c2852",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge7.png?alt=media&token=88279489-1ede-4431-9cea-e5c2f3b5a936",
        pointsRequired: 7000,
        priority: 19
    }, {
        "name": "Badges 16",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge6grey.png?alt=media&token=78cbcb2f-ce97-4294-a6d0-2cd175e3608e",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge6.png?alt=media&token=2b7eb760-e68f-41bd-bde9-c36333aa8a5a",
        pointsRequired: 7500,
        priority: 20
    }, {
        "name": "Badges 17",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge9grey.png?alt=media&token=61e0b02d-7f7d-4c97-818f-e58bc5c3599f",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge9.png?alt=media&token=654a8b4b-1803-4289-bd27-fc51aa14a7f3",
        pointsRequired: 8000,
        priority: 21
    }, {
        "name": "Badges 18",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge12grey.png?alt=media&token=d8ceb4f3-9181-4220-aad2-5ad1aec11cb2",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge12.png?alt=media&token=5d3e3dcb-b29f-4111-9a7a-a144882818c9",
        pointsRequired: 8500,
        priority: 22
    }, {
        "name": "Badges 19",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge15grey.png?alt=media&token=242f9b75-e950-4316-97cf-fddc98f77b14",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge15.png?alt=media&token=88281240-a693-4354-a32a-c7336045a775",
        pointsRequired: 9000,
        priority: 23
    }, {
        "name": "Badges 20",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge16grey.png?alt=media&token=6b6485f8-f635-4744-ab4b-d1732d59ca0f",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge16.png?alt=media&token=4ffb9b3e-79ca-4ada-92cb-9d0e48c0efe4",
        pointsRequired: 10000,
        priority: 24
    }, {
        "name": "Badges 21",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge19grey.png?alt=media&token=05a0d7f5-c77d-41c2-9b5f-d27d4284d266",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge19.png?alt=media&token=5c328fd3-3665-4e78-9be4-f6d024ddcf36",
        pointsRequired: 11000,
        priority: 25
    }, {
        "name": "Badges 22",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge20grey.png?alt=media&token=7cb375b5-0427-44d6-8347-088cbb9ff25e",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge20.png?alt=media&token=be8e57a4-d3da-4485-aa5a-26461a0a4cae",
        pointsRequired: 12000,
        priority: 26
    }, {
        "name": "Badges 23",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge21grey.png?alt=media&token=53c7490d-5ad6-4d27-a0a9-8c73624af2d8",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge21.png?alt=media&token=cd863f55-2a5e-46a5-a046-e7bf64e66a9e",
        pointsRequired: 13000,
        priority: 27
    }, {
        "name": "Badges 24",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge22grey.png?alt=media&token=0a3de0f5-0dd3-4b41-b1cf-ace7b7b8fca3",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge22.png?alt=media&token=902cd9ce-aded-45f1-a383-a6f33c37acd4",
        pointsRequired: 14000,
        priority: 28
    }, {
        "name": "Badges 25",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge26grey.png?alt=media&token=b809fb14-03f2-4244-97b2-030dd13b522a",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge26.png?alt=media&token=50a302e5-794c-4cba-bb66-1b65993189f0",
        pointsRequired: 15000,
        priority: 29
    }, {
        "name": "Badges 26",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge27grey.png?alt=media&token=3abf655e-dcdd-4e29-b467-10aa61471585",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge27.png?alt=media&token=917bcaa2-98c1-4880-83aa-72b5d0f80e11",
        pointsRequired: 16000,
        priority: 30
    }, {
        "name": "Badges 27",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge28grey.png?alt=media&token=9accdf62-0baf-401b-9f4b-a921e823e441",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge28.png?alt=media&token=4bccd98a-58a0-4c4f-8579-541bfd0d655a",
        pointsRequired: 17000,
        priority: 31
    },
    {
        "name": "Badges 28",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge31grey.png?alt=media&token=c25cd10d-36fe-46f1-89c9-5f33f0925837",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge31grey.png?alt=media&token=c25cd10d-36fe-46f1-89c9-5f33f0925837",
        pointsRequired: 18000,
        priority: 32
    },
    {
        "name": "Badges 29",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge32grey.png?alt=media&token=dd847068-c56a-4126-ac60-31f2e6fdcd69",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge32.png?alt=media&token=334bb61e-89f8-4e35-8acc-17289699c484",
        pointsRequired: 19000,
        priority: 33
    },
    {
        "name": "Badges 30",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge34grey.png?alt=media&token=d668ab83-5e84-46ce-9748-c41390d71c91",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge34.png?alt=media&token=5a590a84-0b30-49ea-be19-fc984b1423ff",
        pointsRequired: 20000,
        priority: 34
    },
    {
        "name": "Badges 31",
        "greyIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge37grey.png?alt=media&token=e7ec96b3-3f7a-437d-9e32-9f8497b4a4c9",
        "actualIconPath": "https://firebasestorage.googleapis.com/v0/b/nc-operations-uat2.appspot.com/o/badges%2Ficn_badge37.png?alt=media&token=45e28815-c0f8-472e-a445-72a26ade76c1",
        pointsRequired: 21000,
        priority: 35
    }]

module.exports = badges