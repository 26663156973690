import {
  firestoreOperationsDb,
  firestoreMasterDb,
  masterDb,
  operationsDb,
  firebaseStorage,
} from "../../firebase";
import responseMessages from "../../response.json";
import async from "async";
import appConstants from "../../appConstants";
import {
  asyncForEach,
  asyncForEachPromise,
  getCurrentWeek,
} from "../../utils/helperFunctions";
import algoliasearch from "algoliasearch";
import _ from "lodash";
// import data from "../../mediciens";
import badges from "../../badges";
import moment from "moment";

const client = algoliasearch(
  appConstants.ALGOLIA_APPID,
  appConstants.ALGOLIA_ADMIN_KEY
);

const index = client.initIndex("medicines");
const patientIndex = client.initIndex("patients");

export function getData(collection, id, cb) {
  const docRef = firestoreOperationsDb.collection(collection).doc(id);
  docRef.get().then(
    (docSnapshot) => {
      if (docSnapshot.exists) {
        cb(null, { id: docSnapshot.id, ...docSnapshot.data() });
      } else {
        cb(responseMessages.NO_DATA, null);
      }
    },
    (error) => {
      console.log("error:", error);
      cb(error.message, null);
    }
  );

}

export async function getDataOnce(collection, id, cb) {
  try {
    const docRef = firestoreOperationsDb.collection(collection).doc(id);
    const doc = await docRef.get();
    if (!doc.exists) {
      cb(responseMessages.NO_DATA, null);
    } else {
      cb(null, doc.data());
    }
  } catch (error) {
    console.log("error:", error);
    cb(error.message, null);
  }
}

export async function getPatientList(
  searchString,
  filterString,
  userId,
  clinicId,
  userRole,
  cb
) {
  try {
    let patientList = [];
    let patients = [];
    let patientListRef = firestoreOperationsDb.collection("patients");
    if (
      userRole === appConstants.roles.ADMIN ||
      userRole === appConstants.roles.TEAMMEMBER
    ) {
      let patientListData = await patientListRef
        .where("isDeleted", "==", false)
        .where("clinicId", "array-contains", clinicId)
        .get();
      patientListData.forEach((doc) => {
        patientList.push(
          Object.assign(
            {
              id: doc.id,
            },
            doc.data()
          )
        );
      });
      //console.log("1",patientList);
      cb(null, patientList);
    } else {
      const teamRef = await firestoreOperationsDb.collection("careTeam");
      await teamRef
        .where("status", "!=", "deactivated")
        .where("userId", "==", userId)
        .where("isDeleted", "==", false)
        .get()
        .then(async function (team) {
          await team.forEach(function (doc) {
            patients.push(doc.data().patientId);
          });
        })
        .catch(function (error) {
          console.log("error:", error);
          cb(error.message, null);
        });

      await asyncForEach(patients, async (val) => {
        let docData = await patientListRef.doc(val).get();
        let data = docData.data();
        if (data) {
          patientList.push(
            Object.assign(
              {
                id: val,
              },
              data
            )
          );
        }
        // await patientIndex
        //   .search(searchString, {
        //     filters: `isDeleted:false AND objectID:${val}`,
        //   })
        //   .then((responses) => {
        //     if (responses && responses.hits) {
        //       patientList = patientList.concat(responses.hits);
        //     }
        //   })
        //   .catch(function (error) {
        //     console.log("error:", error);
        //     cb(error.message, null);
        //   });
      });
      //console.log("2",patientList);
      cb(null, patientList);
    }
  } catch (error) {
    console.log("error in get patinet", error);
    cb(error.message, null);
  }
  // let patientList = [];
  // let patients = [];

  // if (userRole === appConstants.roles.ADMIN) {
  //   patientIndex
  //     .search(searchString,
  //       {
  //       filters: `isDeleted:false AND clinicId:${clinicId}`,
  //     }
  //     )
  //     .then((responses) => {
  //       if (responses && responses.hits) {
  //         patientList = responses.hits;
  //       }
  //       cb(null, patientList);
  //     })
  //     .catch(function (error) {
  //       console.log("error:", error);
  //       cb(error.message, null);
  //     });
  // } else {
  // const teamRef = await firestoreOperationsDb.collection("careTeam");
  // await teamRef
  //   .where("status", "!=", "deactivated")
  //   .where("userId", "==", userId)
  //   .where("isDeleted", "==", false)
  //   .get()
  //   .then(async function (team) {
  //     await team.forEach(function (doc) {
  //       patients.push(doc.data().patientId);
  //     });
  //   })
  //   .catch(function (error) {
  //     console.log("error:", error);
  //     cb(error.message, null);
  //   });

  // await asyncForEach(patients, async (val) => {
  //   await patientIndex
  //     .search(searchString, {
  //       filters: `isDeleted:false AND objectID:${val}`,
  //     })
  //     .then((responses) => {
  //       if (responses && responses.hits) {
  //         patientList = patientList.concat(responses.hits);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log("error:", error);
  //       cb(error.message, null);
  //     });
  // });
  // cb(null, patientList);
  // }
}

export function getConditions(patientId, cb) {
  let conditionList = [];
  let taskList = [];
  let userList = [];
  async.parallel(
    [
      function (cb1) {
        const conditionRef = firestoreOperationsDb.collection("conditions");
        conditionRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .where("isCompleted", "==", false)
          .get()
          .then(async function (conditions) {
            await conditions.forEach(async function (doc) {
              let condition = doc.data();
              condition.conditionId = doc.id;
              conditionList.push(condition);
            });
            cb1(null, conditionList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const taskRef = firestoreOperationsDb.collection("tasks");
        taskRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .orderBy("dueDate")
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.taskId = doc.id;
              taskList.push(task);
            });
            cb1(null, taskList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const userRef = firestoreOperationsDb.collection("users");
        userRef
          .where("isDeleted", "==", false)
          .get()
          .then(async function (users) {
            await users.forEach(async function (doc) {
              userList.push(doc.data());
            });
            cb1(null, userList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
    ],
    async (error, result) => {
      if (error) {
        console.log("error:", error);
        cb(error.message, null);
      }
      let condition = result[0];
      let task = result[1];
      let users = result[2];
      condition =
        condition &&
        (await condition.map((val) => {
          val.createdBy = users.find((u) => u.userId == val.createdBy)
            ? users.find((u) => u.userId == val.createdBy)
            : {};
          val.tasks = task.filter((t) => t.conditionId == val.conditionId);
          val.tasks =
            val.tasks &&
            val.tasks.map((val1) => {
              val1.dateToSort = val1.dueDate;

              if (
                val1.type === appConstants.taskTypes.trackProgress ||
                val1.type === appConstants.taskTypes.treatment ||
                val1.type === appConstants.taskTypes.medication
              ) {
                val1.taskPendingDates = val1.taskPendingDates || [];

                if (val1.taskPendingDates.length > 1) {
                  let date = moment().format(appConstants.DATE_FORMAT);
                  val1.taskPendingDates.sort((a, b) => {
                    return new Date(a) - new Date(b);
                  });
                  if (
                    val1.taskPendingDates.find(
                      (a) => new Date(a) >= new Date(date)
                    )
                  ) {
                    let dateToCompleteTheTask = val1.taskPendingDates.find(
                      (a) => new Date(a) >= new Date(date)
                    );
                    if (dateToCompleteTheTask)
                      val1.dateToSort = dateToCompleteTheTask;
                  }
                }
              }

              val1.createdBy = users.find((u) => u.userId == val1.createdBy)
                ? users.find((u) => u.userId == val1.createdBy)
                : {};
              console.log("val1:", val1.dateToSort);
              return val1;
            });
          val.tasks = val.tasks.sort((a, b) => {
            return new Date(a.dateToSort) - new Date(b.dateToSort);
          });

          return val;
        }));
      cb(null, condition);
    }
  );
}

export function getHealthNotes(patientId, cb) {
  let conditionList = [];
  let notesList = [];
  async.parallel(
    [
      function (cb1) {
        const conditionRef = firestoreOperationsDb.collection("conditions");
        conditionRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .where("isCompleted", "==", false)
          .get()
          .then(async function (conditions) {
            await conditions.forEach(async function (doc) {
              let condition = doc.data();
              condition.conditionId = doc.id;
              conditionList.push(condition);
            });
            cb1(null, conditionList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const taskRef = firestoreOperationsDb.collection("healthNotes");
        taskRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (notes) {
            await notes.forEach(async function (doc) {
              let note = doc.data();
              note.noteId = doc.id;
              notesList.push(note);
            });
            cb1(null, notesList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
    ],
    async (error, result) => {
      if (error) {
        console.log("error:", error);
        cb(error.message, null);
      }
      let condition = result[0];
      let note = result[1];
      let users = result[2];
      condition =
        condition &&
        (await condition.map((val) => {
          val.notes = note.filter((t) => t.conditionId == val.conditionId);
          return val;
        }));
      cb(null, condition);
    }
  );
}

export async function getTeamMembers(clinicId = "", cb) {
  let members = [];
  let memberRef = await firestoreOperationsDb.collection("users");
  memberRef = memberRef.where("isDeleted", "==", false);
  if (clinicId) memberRef = memberRef.where("clinicId", "==", clinicId);

  memberRef
    .get()
    .then(async function (memberData) {
      await memberData.forEach(async function (doc) {
        let member = doc.data();
        member.memberId = doc.id;
        members.push(member);
      });
      cb(null, members);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export function getConditionTemplate(cb) {
  let templateList = [];
  const templateRef = firestoreMasterDb.collection("template");
  templateRef
    .where("isDeleted", "==", false)
    .get()
    .then(async function (templateData) {
      await templateData.forEach(async function (doc) {
        let template = doc.data();
        template.templateId = doc.id;
        templateList.push(template);
      });
      cb(null, templateList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export function checkIFConditionExists(conditionName, patientId) {
  return new Promise((resolve, reject) => {
    const conditionRef = firestoreOperationsDb.collection("conditions");
    conditionRef
      .where("conditionToLowerCase", "==", conditionName.toLowerCase())
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .get()
      .then(async function (conditions) {
        resolve(conditions.size);
      })
      .catch(function (error) {
        console.log("error:", error);
        reject(error);
      });
  });
}

export function checkIFConditionExistsList(conditionName, patientId) {
  return new Promise((resolve, reject) => {
    const conditionRef = firestoreOperationsDb.collection("conditions");
    conditionRef
      .where("conditionToLowerCase", "==", conditionName.toLowerCase())
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .get()
      .then(async function (conditions) {
        let conditionList = [];
        conditions.forEach((doc) => {
          let con = doc.data();
          con.conditionId = doc.id;
          conditionList.push(con);
        });
        resolve(conditionList);
      })
      .catch(function (error) {
        console.log("error:", error);
        reject(error);
      });
  });
}

export function checkIFPatientExists(email, phoneNumber) {
  let patients = [];
  let patients1 = [];
  let emailExists = false;
  let phoneExists = false;

  return new Promise(async (resolve, reject) => {
    async.parallel(
      [
        function (cb1) {
          let patientRef = firestoreOperationsDb
            .collection("patients")
            .where("email", "==", email)
            .where("isDeleted", "==", false);

          patientRef
            .get()
            .then(async function (patientRes) {
              await patientRes.forEach(async function (doc) {
                let patient = doc.data();
                patient.patientId = doc.id;
                patients.push(patient);
              });
              cb1(null, patients);
            })
            .catch(function (error) {
              console.log("error:", error);
              cb1(error, null);
            });
        },
        function (cb1) {
          let patientRef = firestoreOperationsDb
            .collection("patients")
            .where("isDeleted", "==", false)
            .where("phoneNumber", "==", phoneNumber);

          patientRef
            .get()
            .then(async function (patientRes) {
              await patientRes.forEach(async function (doc) {
                console.log("doc:", doc.data());
                patients1.push(doc.data());
              });
              cb1(null, patients1);
            })
            .catch(function (error) {
              console.log("error:", error);
              cb1(error, null);
            });
        },
      ],
      async (error, result) => {
        if (error) {
          console.log("error:", error);
          reject(error);
        }
        result[0] = result[0].concat(result[1]);
        result[0] = _.uniqBy(result[0], "userId");
        console.log("result[0]", result[0]);
        if (result[0] && result[0].length) {
          if (
            result[0].filter(
              (p) => p.email === email && p.phoneNumber === phoneNumber
            ).length
          ) {
            resolve({
              patients: result[0],
              emailExists: true,
              phoneExists: true,
            });
          } else if (
            result[0].filter(
              (p) => p.email === email && p.phoneNumber !== phoneNumber
            ).length
          ) {
            resolve({
              patients: result[0],
              emailExists: true,
              phoneExists: false,
            });
          } else if (
            result[0].filter(
              (p) => p.phoneNumber === phoneNumber && p.email !== email
            ).length
          ) {
            resolve({
              patients: result[0],
              emailExists: false,
              phoneExists: true,
            });
          } else {
            resolve({
              patients: result[0],
              emailExists: false,
              phoneExists: false,
            });
          }
        } else {
          resolve({
            patients: [],
            emailExists: false,
            phoneExists: false,
          });
        }
      }
    );
  });

  // return new Promise((resolve, reject) => {
  //   const patientRef = firestoreOperationsDb.collection("patients");
  //   patientRef
  //     .where("email", "==", email)
  //     .where("isDeleted", "==", false)
  //     .get()
  //     .then(async function (patientRes) {
  //       await patientRes.forEach(async function (doc) {
  //         let patient = doc.data();
  //         patient.patientId = doc.id;
  //         patients.push(patient);
  //       });
  //       resolve(patients);
  //     })
  //     .catch(function (error) {
  //       console.log("error:", error);
  //       reject(error);
  //     });
  // });
}

export function checkIFUserExists(email, phoneNumber, userId) {
  let users = [],
    users1 = [];
  return new Promise(async (resolve, reject) => {
    async.parallel(
      [
        function (cb1) {
          let userRef = firestoreOperationsDb
            .collection("users")
            .where("isDeleted", "==", false)
            .where("email", "==", email);

          if (userId) userRef = userRef.where("userId", "!=", userId);
          userRef
            .get()
            .then(async function (userRes) {
              await userRes.forEach(async function (doc) {
                console.log("doc:", doc.data());
                users.push(doc.data());
              });
              cb1(null, users);
            })
            .catch(function (error) {
              console.log("error:", error);
              cb1(error, null);
            });
        },
        function (cb1) {
          let userRef = firestoreOperationsDb
            .collection("users")
            .where("isDeleted", "==", false)
            .where("phoneNumber", "==", phoneNumber);

          if (userId) userRef = userRef.where("userId", "!=", userId);
          userRef
            .get()
            .then(async function (userRes) {
              await userRes.forEach(async function (doc) {
                console.log("doc:", doc.data());
                users1.push(doc.data());
              });
              cb1(null, users1);
            })
            .catch(function (error) {
              console.log("error:", error);
              cb1(error, null);
            });
        },
      ],
      async (error, result) => {
        if (error) {
          console.log("error:", error);
          reject(error);
        }
        result[0] = result[0].concat(result[1]);
        resolve(result[0]);
      }
    );

    let userRef = await firestoreOperationsDb
      .collection("users")
      .where("isDeleted", "==", false);
    console.log("userId:", email, phoneNumber, userId);
    if (email) userRef = userRef.where("email", "==", email);
    if (phoneNumber) userRef = userRef.where("phoneNumber", "==", phoneNumber);
    if (userId) userRef = userRef.where("userId", "!=", userId);
    await userRef
      .get()
      .then(async function (userRes) {
        await userRes.forEach(async function (doc) {
          console.log("doc:", doc.data());
          users.push(doc.data());
        });
        resolve(users);
      })
      .catch(function (error) {
        console.log("error:", error);
        reject(error);
      });
  });
}

export function identificationNumberExists(identificationNumber) {
  return new Promise(async (resolve, reject) => {
    let users = [];
    firestoreOperationsDb
      .collection("patients")
      .where("isDeleted", "==", false)
      .where("identificationNumber", "==", identificationNumber)
      .get()
      .then(async function (userRes) {
        await userRes.forEach(async function (doc) {
          console.log("doc:", doc.data());
          users.push(doc.data());
        });
        resolve(users);
      })
      .catch(function (error) {
        console.log("error:", error);
        reject(error);
      });
  });
}

export async function getPatientViaCode(surName, careCode, cb) {
  const patientRef = firestoreOperationsDb.collection("patients");
  patientRef
    .where("careCode", "==", careCode)
    .where("isDeleted", "==", false)
    .get()
    .then(async function (patientRes) {
      if (patientRes.size != 0) {
        await patientRes.forEach(async function (doc) {
          let patient = doc.data();
          if (patient.surName.toLowerCase() == surName.toLowerCase()) {
            cb(null, patient);
          } else {
            cb(responseMessages.INVALID_SURNAME, null);
          }
        });
      } else {
        cb(responseMessages.INVALID_CODE, null);
      }
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export async function getDocumentTypes(cb) {
  let typeList = [];
  const typeRef = firestoreMasterDb.collection("documentTypes");
  typeRef
    .where("isDeleted", "==", false)
    .get()
    .then(async function (typeData) {
      await typeData.forEach(async function (doc) {
        let type = doc.data();
        type.typeId = doc.id;
        typeList.push(type);
      });
      cb(null, typeList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export async function getConditionsList(patientId, cb) {
  let conditionList = [];
  const conditionRef = firestoreOperationsDb.collection("conditions");
  conditionRef
    .where("patientId", "==", patientId)
    .where("isDeleted", "==", false)
    .get()
    .then(async function (conditions) {
      await conditions.forEach(async function (doc) {
        let condition = doc.data();
        condition.conditionId = doc.id;
        conditionList.push(condition);
      });
      cb(null, conditionList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export async function getProgressData(patientId, date, cb) {
  try {
    console.log("date", date);
    let haveStepGoalTaskData = [],
      haveSleepGoalTaskData = [],
      todaySleepCountProgressData = [],
      haveCaloriesIntakeTaskData = [],
      todayCaloriesIntakeProgressData = [],
      todayStepCountProgressData = [],
      haveBodyWeightTaskData = [],
      bodyWeightTaskProgressData = [],
      haveBMITaskData = [],
      BMITaskProgressData = [],
      haveTemperatureTaskData = [],
      temperatureTaskProgressData = [],
      havePainLevelTaskData = [],
      painLevelProgressData = [],
      haveBloodPressureTaskData = [],
      bloodPressureProgressData = [],
      haveBloodGlucoseTaskData = [],
      bloodGlucoseProgressData = [];

    let taskRef = firestoreOperationsDb.collection("tasks");
    let taskProgressRef = firestoreOperationsDb.collection("stepCountProgress");
    let sleepProgressRef = firestoreOperationsDb.collection("sleepProgress");
    let bloodPressureProgressRef = firestoreOperationsDb.collection(
      "bloodPressureProgress"
    );

    let bodyWeightProgressRef =
      firestoreOperationsDb.collection("bodyWeightProgress");
    let painLevelProgressRef =
      firestoreOperationsDb.collection("painLevelProgress");
    let temperatureProgressRef = firestoreOperationsDb.collection(
      "temperatureProgress"
    );
    let calorieIntakeProgressRef = firestoreOperationsDb.collection(
      "calorieIntakeProgress"
    );
    //For BMI Task
    let bmiProgressRef = firestoreOperationsDb.collection("BMIProgress");

    let haveBMITask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where("trackProgress.trackingType", "==", appConstants.trackingTypes.bmi)
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    haveBMITask.forEach((ele) => {
      let doc = ele.data();
      haveBMITaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    let BMIProgress = await bmiProgressRef
      .where("patientId", "==", patientId)
      .orderBy("completedOn", "desc")
      .limit(1)
      .get();

    BMIProgress.forEach((ele) => {
      let doc = ele.data();
      BMITaskProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    //For BloodGlucose Task

    let bloodGlucoseProgressRef = firestoreOperationsDb.collection(
      "bloodGlucoseProgress"
    );

    let haveBloodGlucoseTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.bloodGlucose
      )
      .limit(1)
      .get();

    haveBloodGlucoseTask.forEach((ele) => {
      let doc = ele.data();
      console.log("Paint lavele donc", doc);
      haveBloodGlucoseTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    let dayss = getCurrentWeek();

    let bloodGlucoseProgress = await bloodGlucoseProgressRef
      .where("patientId", "==", patientId)
      .where("date", ">=", dayss[0])
      .where("date", "<=", dayss[dayss.length - 1])
      .get();

    bloodGlucoseProgress.forEach((ele) => {
      let doc = ele.data();
      bloodGlucoseProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    dayss = dayss.map((date) => {
      let obj = {
        date: date,
        painLevel: 0,
        color: "#dfdfdf",
      };
      let found = bloodGlucoseProgressData.find((e) => e.date === date);
      if (found) {
        obj.painLevel = found.input;
        obj.color = "#12cae6";
      }
      return obj;
    });

    //For Sleep Progress
    let haveSleepTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.sleep
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    let todaySleepTask = await sleepProgressRef
      .where("patientId", "==", patientId)
      .where("date", "==", date)
      .limit(1)
      .get();

    haveSleepTask.forEach((ele) => {
      let doc = ele.data();
      haveSleepGoalTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    todaySleepTask.forEach((ele) => {
      let doc = ele.data();
      todaySleepCountProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    //For StepCount Progress
    let haveStepGoalTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.stepCount
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    let todayStepCountProgress = await taskProgressRef
      .where("patientId", "==", patientId)
      .where("date", "==", date)
      .limit(1)
      .get();

    haveStepGoalTask.forEach((ele) => {
      let doc = ele.data();
      haveStepGoalTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    todayStepCountProgress.forEach((ele) => {
      let doc = ele.data();
      todayStepCountProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    // FOR CALORIE INTAKE
    let haveCaloriesIntake = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.caloricIntake
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    let todayCalorieIntakeProgress = await calorieIntakeProgressRef
      .where("patientId", "==", patientId)
      .where("date", "==", date)
      .limit(1)
      .get();

    haveCaloriesIntake.forEach((ele) => {
      let doc = ele.data();
      haveCaloriesIntakeTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    todayCalorieIntakeProgress.forEach((ele) => {
      let doc = ele.data();
      todayCaloriesIntakeProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    //For Temperature Task
    let haveTemperatureTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.temperature
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    haveTemperatureTask.forEach((ele) => {
      let doc = ele.data();
      haveTemperatureTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    let bodyTemperatureProgress = await temperatureProgressRef
      .where("patientId", "==", patientId)
      .orderBy("completedOn", "desc")
      .limit(1)
      .get();

    bodyTemperatureProgress.forEach((ele) => {
      let doc = ele.data();
      temperatureTaskProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });
    //For BodyWight Task
    let havebodyWeightTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.bodyWeight
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    havebodyWeightTask.forEach((ele) => {
      let doc = ele.data();
      haveBodyWeightTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    let bodyWeightProgress = await bodyWeightProgressRef
      .where("patientId", "==", patientId)
      .orderBy("completedOn", "desc")
      .limit(1)
      .get();

    bodyWeightProgress.forEach((ele) => {
      let doc = ele.data();
      bodyWeightTaskProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    // bloodPressureProgressRef
    let haveBloodPressureTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.bloodPressure
      )
      .limit(1)
      .get();

    haveBloodPressureTask.forEach((ele) => {
      let doc = ele.data();
      console.log("Paint lavele donc", doc);
      haveBloodPressureTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    let daysss = getCurrentWeek();

    let bloodPressureProgress = await bloodPressureProgressRef
      .where("patientId", "==", patientId)
      .where("date", ">=", daysss[0])
      .where("date", "<=", daysss[daysss.length - 1])
      .get();

    bloodPressureProgress.forEach((ele) => {
      let doc = ele.data();
      bloodPressureProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    daysss = daysss.map((date) => {
      let obj = {
        date: date,
        min: 0,
        max: 0,
        color: "#dfdfdf",
      };
      let found = bloodPressureProgressData.find((e) => e.date === date);
      if (found) {
        obj.max = found.max;
        obj.min = found.min;
        obj.color = "#12cae6";
      }
      return obj;
    });
    //For PainLevel Task
    let havePainLevelTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.painLevel
      )
      .limit(1)
      .get();

    havePainLevelTask.forEach((ele) => {
      let doc = ele.data();
      console.log("Paint lavele donc", doc);
      havePainLevelTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    let days = getCurrentWeek();

    let painLevelProgress = await painLevelProgressRef
      .where("patientId", "==", patientId)
      .where("date", ">=", days[0])
      .where("date", "<=", days[days.length - 1])
      .get();

    painLevelProgress.forEach((ele) => {
      let doc = ele.data();
      painLevelProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    days = days.map((date) => {
      let obj = {
        date: date,
        painLevel: 0,
        color: "#dfdfdf",
      };
      let found = painLevelProgressData.find((e) => e.date === date);
      if (found) {
        obj.painLevel = found.input;
        obj.color = "#12cae6";
      }
      return obj;
    });

    //Medication task
    let medicationComplainent = 0,
      haveMedicationTaskData = [];

    let haveMedicationTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where("type", "==", appConstants.taskTypes.medication)
      .get();

    haveMedicationTask.forEach((ele) => {
      let doc = ele.data();
      haveMedicationTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
      if (doc.compliance) {
        medicationComplainent += doc.compliance;
      }
    });

    //Media Galary task
    let haveTreatmentTyepTaskData = [];

    let haveTreatmentTyepTasK = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where("type", "==", appConstants.taskTypes.treatment)
      .limit(1)
      .get();

    haveTreatmentTyepTasK.forEach((ele) => {
      let doc = ele.data();
      haveTreatmentTyepTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    cb(null, {
      haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
      stepGoalTaskObject: haveStepGoalTaskData[0]
        ? haveStepGoalTaskData[0]
        : {},
      todayStepCount:
        todayStepCountProgressData.length > 0
          ? todayStepCountProgressData[0].input
          : 0,

      haveSleepTask: haveSleepGoalTaskData.length > 0 ? true : false,
      sleepGoalTaskObject: haveSleepGoalTaskData[0]
        ? haveSleepGoalTaskData[0]
        : {},
      todaySleepCount:
        todaySleepCountProgressData.length > 0
          ? todaySleepCountProgressData[0].input
          : 0,

      haveCaloriesIntakeTask:
        haveCaloriesIntakeTaskData.length > 0 ? true : false,
      caloriesIntakeTaskObject: haveCaloriesIntakeTaskData[0]
        ? haveCaloriesIntakeTaskData[0]
        : {},
      todayCaloriesIntakeCount:
        todayCaloriesIntakeProgressData.length > 0
          ? todayCaloriesIntakeProgressData[0].input
          : 0,
      // haveTemperatureTaskData = [],
      // temperatureTaskProgressData = [],
      haveTemperatureTask: haveTemperatureTaskData.length > 0 ? true : false,
      bodyTemperatureTaskObject: haveTemperatureTaskData[0]
        ? haveTemperatureTaskData[0]
        : {},
      currentTemperature:
        temperatureTaskProgressData.length > 0
          ? temperatureTaskProgressData[0].input
          : haveTemperatureTaskData[0]
            ? haveTemperatureTaskData[0].trackProgress &&
            haveTemperatureTaskData[0].trackProgress.currentWeight
            : 0,

      haveBMITask: haveBMITaskData.length > 0 ? true : false,
      BMITaskObject: haveBMITaskData[0] ? haveBMITaskData[0] : {},
      currentBMI:
        BMITaskProgressData.length > 0
          ? BMITaskProgressData[0].input
          : haveBMITaskData[0]
            ? haveBMITaskData[0].trackProgress &&
            haveBMITaskData[0].trackProgress.currentWeight
            : 0,

      havebodyWeightTask: haveBodyWeightTaskData.length > 0 ? true : false,
      bodyWeightTaskObject: haveBodyWeightTaskData[0]
        ? haveBodyWeightTaskData[0]
        : {},
      currentWeight:
        bodyWeightTaskProgressData.length > 0
          ? bodyWeightTaskProgressData[0].input
          : haveBodyWeightTaskData[0]
            ? haveBodyWeightTaskData[0].trackProgress &&
            haveBodyWeightTaskData[0].trackProgress.currentWeight
            : 0,

      haveBloodPressureTask:
        haveBloodPressureTaskData.length > 0 ? true : false,
      bloodPressureProgress: daysss,

      havePainLevelTask: havePainLevelTaskData.length > 0 ? true : false,
      painLevelProgress: days,

      haveBloodGlucoseTask: haveBloodGlucoseTaskData.length > 0 ? true : false,
      bloodGlucoseProgress: dayss,

      haveMedicationTask: haveMedicationTaskData.length > 0 ? true : false,
      medicationComplainent,
      medicationTaskDate: haveMedicationTaskData,

      haveTreatmentTask: haveTreatmentTyepTaskData.length > 0 ? true : false,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getStepCountModalData(patientId, start, end, cb) {
  try {
    let haveStepGoalTaskData = [],
      todayStepCountProgressData = [],
      stepCountHistoryData = [];

    let taskRef = firestoreOperationsDb.collection("tasks");
    let taskProgressRef = firestoreOperationsDb.collection("stepCountProgress");

    let haveStepGoalTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.stepCount
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    let todayStepCountProgress = await taskProgressRef
      .where("patientId", "==", patientId)
      // .where("date", "==", date)
      .where("completedOn", ">", new Date(start))
      .where("completedOn", "<", new Date(end))
      // .limit(1)      .limit(1)
      .get();

    haveStepGoalTask.forEach((ele) => {
      let doc = ele.data();
      haveStepGoalTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    todayStepCountProgress.forEach((ele) => {
      let doc = ele.data();
      todayStepCountProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    let stepCountHistory = await taskProgressRef
      .where("patientId", "==", patientId)
      .get();

    stepCountHistory.forEach((ele) => {
      let doc = ele.data();
      stepCountHistoryData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    todayStepCountProgressData = todayStepCountProgressData.filter(
      (thing, index, self) =>
        index ===
        self.findIndex((t) => t.date === thing.date && t.input >= thing.input)
    );
    let total = 0;
    todayStepCountProgressData.map((e) => {
      total = total + e.input;
    });
    let average = total / todayStepCountProgressData.length;

    cb(null, {
      haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
      stepGoalTaskObject: haveStepGoalTaskData[0]
        ? haveStepGoalTaskData[0]
        : {},
      todayStepCount:
        todayStepCountProgressData.length > 0 ? Math.floor(average) : 0,
      stepCountHistoryData: stepCountHistoryData,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getSleepCountModalData(patientId, start, end, cb) {
  try {
    let haveStepGoalTaskData = [],
      todayStepCountProgressData = [],
      stepCountHistoryData = [];

    let taskRef = firestoreOperationsDb.collection("tasks");
    let taskProgressRef = firestoreOperationsDb.collection("sleepProgress");

    let haveStepGoalTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.sleep
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    let todayStepCountProgress = await taskProgressRef
      .where("patientId", "==", patientId)
      // .where("date", "==", date)
      .where("completedOn", ">", new Date(start))
      .where("completedOn", "<", new Date(end))
      // .limit(1)
      .get();

    haveStepGoalTask.forEach((ele) => {
      let doc = ele.data();
      haveStepGoalTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    todayStepCountProgress.forEach((ele) => {
      let doc = ele.data();
      todayStepCountProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    let stepCountHistory = await taskProgressRef
      .where("patientId", "==", patientId)
      .get();

    stepCountHistory.forEach((ele) => {
      let doc = ele.data();
      stepCountHistoryData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });
    todayStepCountProgressData = todayStepCountProgressData.filter(
      (thing, index, self) =>
        index ===
        self.findIndex((t) => t.date === thing.date && t.input >= thing.input)
    );
    let total = 0;
    todayStepCountProgressData.map((e) => {
      total = total + e.input;
    });
    let average = total / todayStepCountProgressData.length;

    cb(null, {
      haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
      stepGoalTaskObject: haveStepGoalTaskData[0]
        ? haveStepGoalTaskData[0]
        : {},
      todayStepCount:
        todayStepCountProgressData.length > 0 ? Math.floor(average) : 0,
      stepCountHistoryData: stepCountHistoryData,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getCalorieIntaketModalData(patientId, start, end, cb) {
  try {
    let haveStepGoalTaskData = [],
      todayStepCountProgressData = [],
      stepCountHistoryData = [];

    let taskRef = firestoreOperationsDb.collection("tasks");
    let taskProgressRef = firestoreOperationsDb.collection(
      "calorieIntakeProgress"
    );

    let haveStepGoalTask = await taskRef
      .where("patientId", "==", patientId)
      .where("isDeleted", "==", false)
      .where("status", "==", appConstants.status.active.name)
      .where(
        "trackProgress.trackingType",
        "==",
        appConstants.trackingTypes.caloricIntake
      )
      .orderBy("dueDate", "desc")
      .limit(1)
      .get();

    let todayStepCountProgress = await taskProgressRef
      .where("patientId", "==", patientId)
      // .where("date", "==", date)
      .where("completedOn", ">", new Date(start))
      .where("completedOn", "<", new Date(end))
      // .limit(1)      .limit(1)
      .get();

    haveStepGoalTask.forEach((ele) => {
      let doc = ele.data();
      haveStepGoalTaskData.push(
        Object.assign(
          {
            taskId: ele.id,
          },
          doc
        )
      );
    });

    todayStepCountProgress.forEach((ele) => {
      let doc = ele.data();
      todayStepCountProgressData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    let stepCountHistory = await taskProgressRef
      .where("patientId", "==", patientId)
      .get();

    stepCountHistory.forEach((ele) => {
      let doc = ele.data();
      stepCountHistoryData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    todayStepCountProgressData = todayStepCountProgressData.filter(
      (thing, index, self) =>
        index ===
        self.findIndex((t) => t.date === thing.date && t.input >= thing.input)
    );
    let total = 0;
    todayStepCountProgressData.map((e) => {
      total = total + e.input;
    });
    let average = total / todayStepCountProgressData.length;

    cb(null, {
      haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
      stepGoalTaskObject: haveStepGoalTaskData[0]
        ? haveStepGoalTaskData[0]
        : {},
      todayStepCount:
        todayStepCountProgressData.length > 0 ? Math.floor(average) : 0,
      stepCountHistoryData: stepCountHistoryData,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getMediaDataData(patientId, cb) {
  try {
    let mediaData = [];
    let treatmentProgressRef =
      firestoreOperationsDb.collection("treatmentProgress");

    let treatmentProgress = await treatmentProgressRef
      .where("patientId", "==", patientId)
      .get();

    await asyncForEachPromise(treatmentProgress, async (ele) => {
      let doc = ele.data();

      let taskData = await doc.taskIdRef.get();
      let conditionData = await doc.conditionIdRef.get();

      doc.complationDate = doc.completedOn.toDate();

      doc.taskData = taskData.data();
      doc.conditionData = conditionData.data();
      mediaData.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    let uniqueConditionIds = [
      ...new Set(mediaData.map((obj) => obj.conditionId)),
    ];

    uniqueConditionIds = uniqueConditionIds.map((id) => {
      let tasks = mediaData.filter((e) => e.conditionId == id);
      return {
        conditionName:
          tasks.length > 0
            ? (tasks[0].conditionData &&
              tasks[0].conditionData.conditionName) ||
            ""
            : "",
        conditionId: id,
        tasks,
      };
    });
    cb(null, {
      mediaData: uniqueConditionIds,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getBodyWeightModalData(patientId, cb) {
  try {
    let data = [];
    let bodyWeightProgressRef =
      firestoreOperationsDb.collection("bodyWeightProgress");

    let bodyWeightProgress = await bodyWeightProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    await asyncForEachPromise(bodyWeightProgress, async (ele) => {
      let doc = ele.data();

      if (doc.recordedByRef) {
        let recorderByData = await doc.recordedByRef.get();
        doc.recorderByData = recorderByData.data();
      }
      doc.complationDate = doc.completedOn.toDate();

      data.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    cb(null, {
      data,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getBodyTemperatureModalData(patientId, cb) {
  try {
    let data = [];
    let bodyWeightProgressRef = firestoreOperationsDb.collection(
      "temperatureProgress"
    );

    let bodyWeightProgress = await bodyWeightProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    await asyncForEachPromise(bodyWeightProgress, async (ele) => {
      let doc = ele.data();

      if (doc.recordedByRef) {
        let recorderByData = await doc.recordedByRef.get();
        doc.recorderByData = recorderByData.data();
      }
      doc.complationDate = doc.completedOn.toDate();

      data.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    cb(null, {
      data,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getBMIModalData(patientId, cb) {
  try {
    let data = [];
    let bodyWeightProgressRef = firestoreOperationsDb.collection("BMIProgress");

    let bodyWeightProgress = await bodyWeightProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    await asyncForEachPromise(bodyWeightProgress, async (ele) => {
      let doc = ele.data();

      if (doc.recordedByRef) {
        let recorderByData = await doc.recordedByRef.get();
        doc.recorderByData = recorderByData.data();
      }
      doc.complationDate = doc.completedOn.toDate();

      data.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    cb(null, {
      data,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getMedicationModalData(patientId, cb) {
  try {
    let data = [];
    let medicationProgressRef =
      firestoreOperationsDb.collection("medicationProgress");

    let bodyWeightProgress = await medicationProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    await asyncForEachPromise(bodyWeightProgress, async (ele) => {
      let doc = ele.data();

      if (doc.recordedByRef) {
        let recorderByData = await doc.recordedByRef.get();
        doc.recorderByData = recorderByData.data();
      }
      doc.complationDate = doc.completedOn.toDate();

      data.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    cb(null, {
      data,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getPainLevelModalData(patientId, cb) {
  try {
    let data = [];
    let painLevelProgressRef =
      firestoreOperationsDb.collection("painLevelProgress");

    let bodyWeightProgress = await painLevelProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    bodyWeightProgress.forEach((obj) => {
      data.push(
        Object.assign(
          {
            _id: obj.id,
          },
          obj.data()
        )
      );
    });

    let daysOfWeek = getCurrentWeek();
    daysOfWeek = daysOfWeek.map((date) => {
      let found = data.find((e) => {
        return e.date === date;
      });
      return {
        date: date,
        painLevel: found ? Number(found.input) : 0,
        color: found ? "#12cae6" : "#dfdfdf",
      };
    });

    cb(null, {
      data,
      currentPainLevel: data[0] ? Number(data[0].input) : 0,
      daysOfWeek,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getBloodGlucoseModalData(patientId, cb) {
  try {
    let data = [];
    let painLevelProgressRef = firestoreOperationsDb.collection(
      "bloodGlucoseProgress"
    );

    let bodyWeightProgress = await painLevelProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    bodyWeightProgress.forEach((obj) => {
      data.push(
        Object.assign(
          {
            _id: obj.id,
          },
          obj.data()
        )
      );
    });

    let daysOfWeek = getCurrentWeek();
    daysOfWeek = daysOfWeek.map((date) => {
      let found = data.find((e) => {
        return e.date === date;
      });
      return {
        date: date,
        painLevel: found ? Number(found.input) : 0,
        color: found ? "#12cae6" : "#dfdfdf",
      };
    });

    cb(null, {
      data,
      currentPainLevel: data[0] ? Number(data[0].input) : 0,
      daysOfWeek,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getBloodPressureModalData(patientId, cb) {
  try {
    let data = [];
    let painLevelProgressRef = firestoreOperationsDb.collection(
      "bloodPressureProgress"
    );

    let bodyWeightProgress = await painLevelProgressRef
      .where("patientId", "==", patientId)
      .orderBy("date", "desc")
      .get();

    bodyWeightProgress.forEach((obj) => {
      data.push(
        Object.assign(
          {
            _id: obj.id,
          },
          obj.data()
        )
      );
    });

    console.log("datatatatat", data);

    let daysOfWeek = getCurrentWeek();
    daysOfWeek = daysOfWeek.map((date) => {
      let found = data.find((e) => {
        return e.date === date;
      });
      // console.log("found",found)
      return {
        date: date,
        max: found ? Number(found.max) : 0,
        min: found ? Number(found.min) : 0,
        color: found ? "#12cae6" : "#dfdfdf",
      };
    });
    // console.log("daysOfW/eek",daysOfWeek);
    cb(null, {
      data,
      currentMinBloodPressureLevel: data[0] ? Number(data[0].min) : 0,
      currentMaxBloodPressureLevel: data[0] ? Number(data[0].max) : 0,
      daysOfWeek,
    });
  } catch (error) {
    console.log("error", error);
    cb(error.message, null);
  }
}

export async function getDocumentsList(patientId, cb) {
  let documentList = [];
  let userList = [];
  let conditionList = [];

  async.parallel(
    [
      function (cb1) {
        const docRef = firestoreOperationsDb.collection("documents");
        docRef
          .where("patientId", "==", patientId)
          // .where("isDeleted", "==", false)
          .get()
          .then(async function (documents) {
            await documents.forEach(async function (doc) {
              let document = doc.data();
              console.log("patientId", patientId);
              console.log(
                "🚀 ~ file: readData.action.js:2056 ~ document:",
                document
              );
              document.documentId = doc.id;
              documentList.push(document);
            });
            console.log(
              "🚀 ~ file: readData.action.js:2058 ~ documentList:",
              documentList
            );
            const taskRef = firestoreOperationsDb.collection("tasks");
            taskRef
              .where("type", "==", "readDocument")
              .where("patientId", "==", patientId)
              // .where("isDeleted", "==", false)
              .get()
              .then(async function (documents) {
                await documents.forEach(async function (doc) {
                  let document = doc.data();
                  console.log("patientId", patientId);
                  console.log(
                    "🚀 ~ file: readData.action.js:2056 ~task document:",
                    document
                  );
                  document.documentId = doc.id;
                  if (document.readDocument) {
                    documentList.push({
                      documentId: document.documentId,
                      data: document,
                      isCompleted: document.isCompleted,
                      isDeleted: document.isDeleted,
                      addedAt: "",
                      type: 'task',
                      uploadedBy: document.createdBy,
                      documentType: document.readDocument.typeOfDocument,
                      documentUrl: document.readDocument.documentUrl,
                      documentName: document.readDocument.documentName,
                      conditionId: document.conditionId,
                    });
                  }
                });

                cb1(null, documentList);
              })
              .catch(function (error) {
                console.log("error:", error);
                cb1(error.message, null);
              });
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });


      },

      function (cb1) {
        const userRef = firestoreOperationsDb.collection("users");
        userRef
          // .where("isDeleted", "==", false)
          .get()
          .then(async function (users) {
            await users.forEach(async function (doc) {
              userList.push(doc.data());
            });
            cb1(null, userList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const conditionRef = firestoreOperationsDb.collection("conditions");
        conditionRef
          // .where("isDeleted", "==", false)
          .where("patientId", "==", patientId)
          .get()
          .then(async function (conditions) {
            await conditions.forEach(async function (doc) {
              let condition = doc.data();
              condition.conditionId = doc.id;
              conditionList.push(condition);
            });
            cb1(null, conditionList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
    ],
    async (error, result) => {
      if (error) {
        console.log("error:", error);
        cb(error.message, null);
      }
      let documents = result[0];
      let users = result[1];
      let conditions = result[2];
      documents =
        documents &&
        (await documents.map((val) => {
          val.uploadedBy = users.find((u) => u.userId == val.uploadedBy)
            ? users.find((u) => u.userId == val.uploadedBy)
            : {};
          val.conditionId = conditions.find(
            (c) => c.conditionId == val.conditionId
          )
            ? conditions.find((c) => c.conditionId == val.conditionId)
            : {};
          return val;
        }));
      cb(null, documents);
    }
  );
}

export async function getCompletedConditionList(patientId, cb) {
  let conditionList = [];
  const conditionRef = firestoreOperationsDb.collection("conditions");
  conditionRef
    .where("patientId", "==", patientId)
    .where("isDeleted", "==", false)
    .where("isCompleted", "==", true)
    .get()
    .then(async function (conditions) {
      await conditions.forEach(async function (doc) {
        let condition = doc.data();
        condition.conditionId = doc.id;
        conditionList.push(condition);
      });
      cb(null, conditionList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export async function getCareTeam(patientId, cb) {
  let teamList = [];
  let userList = [];
  async.parallel(
    [
      function (cb1) {
        const teamRef = firestoreOperationsDb.collection("careTeam");
        teamRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (careTeam) {
            await careTeam.forEach(async function (doc) {
              let team = doc.data();
              team.teamId = doc.id;
              teamList.push(team);
            });
            cb1(null, teamList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const userRef = firestoreOperationsDb.collection("users");
        userRef
          .where("isDeleted", "==", false)
          .get()
          .then(async function (users) {
            await users.forEach(async function (doc) {
              userList.push(doc.data());
            });
            cb1(null, userList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
    ],
    async (error, result) => {
      if (error) {
        console.log("error:", error);
        cb(error.message, null);
      }
      let careTeamList = [];
      let careTeam = result[0];
      let users = result[1];

      careTeam &&
        (await careTeam.map(async (val) => {
          val.userId = users.find((u) => u.userId == val.userId)
            ? users.find((u) => u.userId == val.userId)
            : {};
          val.conditions &&
            (await val.conditions.map((val1) => {
              let obj = { ...val, ...val1 };
              delete obj.conditions;
              careTeamList.push(obj);
            }));
        }));
      careTeamList = _.orderBy(careTeamList, ["type"], ["desc"]);
      careTeamList = careTeamList.filter((e, i) => {
        return (
          careTeamList.findIndex((x) => {
            return x.userId === e.userId && x.conditionId === e.conditionId;
          }) == i
        );
      });
      cb(null, careTeamList);
    }
  );
}

export async function getCareTeamForAll(cb) {
  let teamList = [];
  let userList = [];
  async.parallel(
    [
      function (cb1) {
        const teamRef = firestoreOperationsDb.collection("careTeam");
        teamRef
          .where("isDeleted", "==", false)
          .get()
          .then(async function (careTeam) {
            await careTeam.forEach(async function (doc) {
              let team = doc.data();
              team.teamId = doc.id;
              teamList.push(team);
            });
            cb1(null, teamList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const userRef = firestoreOperationsDb.collection("users");
        userRef
          .where("isDeleted", "==", false)
          .get()
          .then(async function (users) {
            await users.forEach(async function (doc) {
              userList.push(doc.data());
            });
            cb1(null, userList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
    ],
    async (error, result) => {
      if (error) {
        console.log("error:", error);
        cb(error.message, null);
      }
      let careTeam = result[0];
      let users = result[1];

      careTeam =
        careTeam &&
        (await careTeam.map((val) => {
          val.userId = users.find((u) => u.userId == val.userId)
            ? users.find((u) => u.userId == val.userId)
            : {};
          return val;
        }));
      careTeam = careTeam.filter((e, i) => {
        return (
          careTeam.findIndex((x) => {
            return x.userId === e.userId && x.patientId === e.patientId;
          }) == i
        );
      });
      cb(null, careTeam);
    }
  );
}

export async function getTasksList(patientId, cb) {
  let taskList = [];
  const taskRef = firestoreOperationsDb.collection("tasks");
  taskRef
    .where("patientId", "==", patientId)
    .where("isDeleted", "==", false)
    .get()
    .then(async function (tasks) {
      await tasks.forEach(async function (doc) {
        let task = doc.data();
        task.taskId = doc.id;
        taskList.push(task);
      });
      cb(null, taskList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export async function getCompletedTasks(patientId, cb) {
  let taskList = [];
  let stepCountList = [];
  let bodyWeightList = [];
  let painLevelList = [];
  let medicationList = [];
  let treatmentList = [];

  async.parallel(
    [
      function (cb1) {
        const taskRef = firestoreOperationsDb.collection("tasks");
        taskRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.taskId = doc.id;
              taskList.push(task);
            });
            cb1(null, taskList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const stepCountRef =
          firestoreOperationsDb.collection("stepCountProgress");
        stepCountRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.recordId = doc.id;
              stepCountList.push(task);
            });
            cb1(null, stepCountList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const bodyWeightRef =
          firestoreOperationsDb.collection("bodyWeightProgress");
        bodyWeightRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.recordId = doc.id;
              bodyWeightList.push(task);
            });
            cb1(null, bodyWeightList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const treatmentRef =
          firestoreOperationsDb.collection("treatmentProgress");
        treatmentRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.recordId = doc.id;
              treatmentList.push(task);
            });
            cb1(null, treatmentList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const painLevelRef =
          firestoreOperationsDb.collection("painLevelProgress");
        painLevelRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.recordId = doc.id;
              painLevelList.push(task);
            });
            cb1(null, painLevelList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
      function (cb1) {
        const medicationRef =
          firestoreOperationsDb.collection("medicationProgress");
        medicationRef
          .where("patientId", "==", patientId)
          .where("isDeleted", "==", false)
          .get()
          .then(async function (tasks) {
            await tasks.forEach(async function (doc) {
              let task = doc.data();
              task.recordId = doc.id;
              medicationList.push(task);
            });
            cb1(null, medicationList);
          })
          .catch(function (error) {
            console.log("error:", error);
            cb1(error.message, null);
          });
      },
    ],
    async (error, result) => {
      if (error) {
        console.log("error:", error);
        cb(error.message, null);
      }
      let progressObj = {};
      progressObj.taskList = result[0];
      progressObj.stepCountList = result[1];
      progressObj.bodyWeightList = result[2];
      progressObj.treatmentList = result[3];
      progressObj.painLevelList = result[4];
      progressObj.medicationList = result[5];

      cb(null, progressObj);
    }
  );
}

export async function getCountryList(cb) {
  return fetch(`${appConstants.firebaseFunctionsUrl}getCountryData`)
    .then((res) => res.json())
    .then(
      (result) => {
        cb(null, result.data);
      },
      (error) => {
        console.log("Error:", error);
        cb(error.message, null);
      }
    );
}

export const getAllDocumentList = async () => {
  try {
  } catch (error) {
    throw error;
  }
};

export async function getMedicines(searchOptions, cb) {
  // index
  //   .search(searchString, searchOptions)
  //   .then(function (responses) {
  //     console.log("res:", responses);
  //     cb(null, responses);
  //   })
  //   .catch(function (error) {
  //     console.log("error:", error);
  //     cb(error.message, null);
  //   });
  fetch(
    `${appConstants.firebaseFunctionsUrl}getMedicineDataAPI?` +
    new URLSearchParams(searchOptions)
  )
    .then((res) => res.json())
    .then(
      (result) => {
        cb(null, result);
      },
      (error) => {
        console.log("Error:", error);
        cb(error.message, null);
      }
    );
}

export async function getSubscriptionPlans(cb) {
  let planList = [];
  const typeRef = firestoreMasterDb.collection("subscriptionPlans");
  typeRef
    .orderBy("priority")
    .get()
    .then(async function (planData) {
      await planData.forEach(async function (doc) {
        let plan = doc.data();
        plan.planId = doc.id;
        planList.push(plan);
      });
      cb(null, planList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export async function getActivitiesData(options, cb) {
  try {
    let activityDataToSend = [];
    let activityRef = firestoreOperationsDb
      .collection(`activities/${options.patientId}/${options.userType}`)
      .orderBy("recordedAt", "desc")
      .limit(options.limit);

    if (options.lastVisible) {
      activityRef = activityRef.startAfter(options.lastVisible);
    }

    let activityData = await activityRef.get();

    await asyncForEachPromise(activityData, async (ele) => {
      let doc = ele.data();

      if (doc.recordedByRef) {
        let recorderByData = await doc.recordedByRef.get();
        doc.recorderByData = recorderByData.data();
      }
      activityDataToSend.push(
        Object.assign(
          {
            id: ele.id,
          },
          doc
        )
      );
    });

    cb(null, {
      data: activityDataToSend,
      lastVisible: activityData.docs[activityData.docs.length - 1],
    });
  } catch (error) {
    console.log("error fetching records", error);
    cb(error.message, null);
  }
}

export async function updateTooltipInfo(updates, clinicId, cb) {
  try {
    const clinicRef = firestoreOperationsDb.collection("clinics").doc(clinicId);
    await clinicRef.update(updates);
    cb(null, true);
  } catch (error) {
    console.log("[Error Update tooltipifo]", error);
    cb(error.message, false);
  }
}

export async function getDocumentLibrary(cb) {
  let docList = [];
  const typeRef = firestoreMasterDb.collection("documentLibrary");
  typeRef
    .get()
    .then(async function (docData) {
      await docData.forEach(async function (doc) {
        let document = doc.data();
        document.documentId = doc.id;
        document.value = doc.id;
        document.label = document.name;
        docList.push(document);
      });
      cb(null, docList);
    })
    .catch(function (error) {
      console.log("error:", error);
      cb(error.message, null);
    });
}

export function checkIFUserExistsV1(email, phoneNumber, userId) {
  let users = [],
    users1 = [];
  return new Promise(async (resolve, reject) => {
    async.parallel(
      [
        function (cb1) {
          let userRef = firestoreOperationsDb
            .collection("users")
            .where("isDeleted", "==", false)
            .where("email", "==", email);

          if (userId) userRef = userRef.where("userId", "!=", userId);
          userRef
            .get()
            .then(async function (userRes) {
              await userRes.forEach(async function (doc) {
                console.log("doc:", doc.data());
                users.push(doc.data());
              });
              cb1(null, users);
            })
            .catch(function (error) {
              console.log("error:", error);
              cb1(error, null);
            });
        },
        function (cb1) {
          let userRef = firestoreOperationsDb
            .collection("users")
            .where("isDeleted", "==", false)
            .where("phoneNumber", "==", phoneNumber);

          if (userId) userRef = userRef.where("userId", "!=", userId);
          userRef
            .get()
            .then(async function (userRes) {
              await userRes.forEach(async function (doc) {
                console.log("doc:", doc.data());
                users1.push(doc.data());
              });
              cb1(null, users1);
            })
            .catch(function (error) {
              console.log("error:", error);
              cb1(error, null);
            });
        },
      ],
      async (error, result) => {
        if (error) {
          console.log("error:", error);
          reject(error);
        }
        if (result[0] && result[0].length) {
          resolve({ status: true, message: responseMessages.EMAIL_EXISTS });
        } else if (result[1] && result[1].length) {
          resolve({ status: true, message: responseMessages.PHONE_EXISTS });
        } else {
          resolve({ status: false });
        }
      }
    );
  });
}

export async function getNotifications(options, cb) {
  try {
    let notificationDataToSend = [];
    let notificationRef = firestoreOperationsDb
      .collection(`notifications`)
      .where("userId", "==", options.userId)
      .orderBy("sentAt", "desc")
      .limit(options.limit);

    if (options.lastVisible) {
      notificationRef = notificationRef.startAfter(options.lastVisible);
    }

    let notificationData = await notificationRef.get();

    await asyncForEachPromise(notificationData, async (ele) => {
      let doc = ele.data();

      if (doc.taskRef) {
        let taskData = await doc.taskRef.get();
        doc.taskData = taskData.data();
      }

      notificationDataToSend.push(Object.assign({ id: ele.id }, doc));
    });

    cb(null, {
      data: notificationDataToSend,
      lastVisible: notificationData.docs[notificationData.docs.length - 1],
    });
  } catch (error) {
    console.log("error fetching records", error);
    cb(error.message, null);
  }
}

export async function getUnreadNotificationCount(options, cb) {
  try {
    console.log("options", options);
    let notificationRef;
    if (options.notificationViewedAt) {
      notificationRef = await firestoreOperationsDb
        .collection(`notifications`)
        .where("userId", "==", options.userId)
        .where("addedOnTimestamp", ">", new Date(options.notificationViewedAt))
        .get();
    } else {
      notificationRef = await firestoreOperationsDb
        .collection(`notifications`)
        .where("userId", "==", options.userId)
        .get();
    }

    cb(null, {
      size: notificationRef.size,
    });
  } catch (error) {
    console.log("error fetching records", error);
    cb(error.message, null);
  }
}

export async function saveDeviceTokens(userId, token, operation, cb) {
  try {
    const userRef = firestoreOperationsDb.collection("users").doc(userId);
    let userData = await userRef.get();
    userData = userData.data();
    console.log("🚀 ~ saveDeviceTokens ~ userData:", userData)
    let tokens = userData.tokens || [];
    if (operation === "add") {
      let index = tokens.findIndex((d) => d.deviceToken === token);
      if (index === -1) {
        tokens.push({
          deviceType: "web",
          deviceToken: token,
        });
      }
    } else {
      if (tokens.length > 0) {
        let index = tokens.findIndex((d) => d.deviceToken === token);
        if (index !== -1) {
          tokens.splice(index, 1);
        }
      }
    }
    await userRef.update({ tokens });
    cb(null, {
      success: true,
    });
  } catch (error) {
    console.log("error fetching records save deviceToken", error);
    cb(error.message, null);
  }
}

export async function updateNotificationViewedAt(options, cb) {
  try {
    let date = moment().utc().format();
    const userRef = firestoreOperationsDb
      .collection("users")
      .doc(options.userId);
    await userRef.update({ notificationViewedAt: date });

    cb(null, {
      date,
    });
  } catch (error) {
    console.log("error fetching records", error);
    cb(error.message, null);
  }
}

// const docRef = firestoreOperationsDb.collection(collection).doc(id);
// docRef.onSnapshot(
//   (docSnapshot) => {
//     if (docSnapshot.exists) {
//       cb(null, docSnapshot.data());
//     } else {
//       cb(responseMessages.NO_DATA, null);
//     }
//   },
//   (error) => {
//     console.log("error:", error);
//     cb(error.message, null);
//   }
// );

export async function getAllPatientData(collection, cb) {
  const docRef = firestoreOperationsDb.collection(collection);
  const patients = await docRef.get();
  console.log("patients", patients);
  let patientsData = [];
  patients.forEach(async (doc) => {
    patientsData.push(
      Object.assign(
        {
          id: doc.id,
        },
        doc.data()
      )
    );
  });
  return patientsData;
}

export async function getAllUsers(collection, cb) {
  const docRef = firestoreOperationsDb.collection(collection);
  const users = await docRef.get();
  let usersData = [];
  users.forEach(async (doc) => {
    usersData.push(
      Object.assign(
        {
          id: doc.id,
        },
        doc.data()
      )
    );
  });
  return usersData;
}

export async function getAllClinicData(collection, cb) {
  const docRef = firestoreOperationsDb.collection(collection);
  const badges = await docRef.get();
  let clinicData = [];
  badges.forEach(async (doc) => {
    clinicData.push(
      Object.assign(
        {
          id: doc.id,
        },
        doc.data()
      )
    );
  });
  return clinicData;
}

export async function getAllDefaultTemplates(collection) {
  const docRef = firestoreMasterDb.collection(collection);
  const templates = await docRef.get();
  let allTemplateList = [];
  templates.forEach(async (doc) => {
    allTemplateList.push(
      Object.assign(
        {
          id: doc.id,
        },
        doc.data()
      )
    );
  });
  return allTemplateList;
}

export const getSingleDefaultTemplate = async (collection, id) => {
  try {
    const docRef = await firestoreMasterDb.collection(collection).doc(id);
    const doc = await docRef.get();
    if (!doc.exists) {
      return { error: responseMessages.NO_DATA, type: "error" };
    } else {
      return { type: "success", data: doc.data() };
    }
  } catch (error) {
    return { type: "error", error: error?.message || "Failed to fetch" };
  }
};

export async function getAllConditions(collection, cb) {
  const docRef = firestoreOperationsDb
    .collection(collection)
    .where("status", "==", "active");
  const conditions = await docRef.get();
  let allConditionList = [];
  conditions.forEach(async (doc) => {
    allConditionList.push(
      Object.assign(
        {
          id: doc.id,
        },
        doc.data()
      )
    );
  });
  return allConditionList;
}





// export async function getDataOnce(collection, id, cb) {
//   try {
//     const docRef = firestoreOperationsDb.collection(collection).doc(id);
//     const doc = await docRef.get();
//     if (!doc.exists) {
//       cb(responseMessages.NO_DATA, null);
//     } else {
//       cb(null, doc.data());
//     }
//   } catch (error) {
//     console.log("error:", error);
//     cb(error.message, null);
//   }
// }

// export async function getPatientList(
//   searchString,
//   filterString,
//   userId,
//   clinicId,
//   userRole,
//   cb
// ) {
//   try {
//     let patientList = [];
//     let patients = [];
//     let patientListRef = firestoreOperationsDb.collection("patients");
//     if (
//       userRole === appConstants.roles.ADMIN ||
//       userRole === appConstants.roles.TEAMMEMBER
//     ) {
//       let patientListData = await patientListRef
//         .where("isDeleted", "==", false)
//         .where("clinicId", "array-contains", clinicId)
//         .get();
//       patientListData.forEach((doc) => {
//         patientList.push(
//           Object.assign(
//             {
//               id: doc.id,
//             },
//             doc.data()
//           )
//         );
//       });
//       //console.log("1",patientList);
//       cb(null, patientList);
//     } else {
//       const teamRef = await firestoreOperationsDb.collection("careTeam");
//       await teamRef
//         .where("status", "!=", "deactivated")
//         .where("userId", "==", userId)
//         .where("isDeleted", "==", false)
//         .get()
//         .then(async function (team) {
//           await team.forEach(function (doc) {
//             patients.push(doc.data().patientId);
//           });
//         })
//         .catch(function (error) {
//           console.log("error:", error);
//           cb(error.message, null);
//         });

//       await asyncForEach(patients, async (val) => {
//         let docData = await patientListRef.doc(val).get();
//         let data = docData.data();
//         if (data) {
//           patientList.push(
//             Object.assign(
//               {
//                 id: val,
//               },
//               data
//             )
//           );
//         }
//         // await patientIndex
//         //   .search(searchString, {
//         //     filters: `isDeleted:false AND objectID:${val}`,
//         //   })
//         //   .then((responses) => {
//         //     if (responses && responses.hits) {
//         //       patientList = patientList.concat(responses.hits);
//         //     }
//         //   })
//         //   .catch(function (error) {
//         //     console.log("error:", error);
//         //     cb(error.message, null);
//         //   });
//       });
//       //console.log("2",patientList);
//       cb(null, patientList);
//     }
//   } catch (error) {
//     console.log("error in get patinet", error);
//     cb(error.message, null);
//   }
//   // let patientList = [];
//   // let patients = [];

//   // if (userRole === appConstants.roles.ADMIN) {
//   //   patientIndex
//   //     .search(searchString,
//   //       {
//   //       filters: `isDeleted:false AND clinicId:${clinicId}`,
//   //     }
//   //     )
//   //     .then((responses) => {
//   //       if (responses && responses.hits) {
//   //         patientList = responses.hits;
//   //       }
//   //       cb(null, patientList);
//   //     })
//   //     .catch(function (error) {
//   //       console.log("error:", error);
//   //       cb(error.message, null);
//   //     });
//   // } else {
//   // const teamRef = await firestoreOperationsDb.collection("careTeam");
//   // await teamRef
//   //   .where("status", "!=", "deactivated")
//   //   .where("userId", "==", userId)
//   //   .where("isDeleted", "==", false)
//   //   .get()
//   //   .then(async function (team) {
//   //     await team.forEach(function (doc) {
//   //       patients.push(doc.data().patientId);
//   //     });
//   //   })
//   //   .catch(function (error) {
//   //     console.log("error:", error);
//   //     cb(error.message, null);
//   //   });

//   // await asyncForEach(patients, async (val) => {
//   //   await patientIndex
//   //     .search(searchString, {
//   //       filters: `isDeleted:false AND objectID:${val}`,
//   //     })
//   //     .then((responses) => {
//   //       if (responses && responses.hits) {
//   //         patientList = patientList.concat(responses.hits);
//   //       }
//   //     })
//   //     .catch(function (error) {
//   //       console.log("error:", error);
//   //       cb(error.message, null);
//   //     });
//   // });
//   // cb(null, patientList);
//   // }
// }

// export function getConditions(patientId, cb) {
//   let conditionList = [];
//   let taskList = [];
//   let userList = [];
//   async.parallel(
//     [
//       function (cb1) {
//         const conditionRef = firestoreOperationsDb.collection("conditions");
//         conditionRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .where("isCompleted", "==", false)
//           .get()
//           .then(async function (conditions) {
//             await conditions.forEach(async function (doc) {
//               let condition = doc.data();
//               condition.conditionId = doc.id;
//               conditionList.push(condition);
//             });
//             cb1(null, conditionList);
//             console.log("conditionList", conditionList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const taskRef = firestoreOperationsDb.collection("tasks");
//         taskRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .orderBy("dueDate")
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.taskId = doc.id;
//               taskList.push(task);
//             });
//             cb1(null, taskList);
//             console.log("taskList", taskList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const userRef = firestoreOperationsDb.collection("users");
//         userRef
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (users) {
//             await users.forEach(async function (doc) {
//               userList.push(doc.data());
//             });
//             cb1(null, userList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//     ],
//     async (error, result) => {
//       if (error) {
//         console.log("error:", error);
//         cb(error.message, null);
//       }
//       let condition = result[0];
//       let task = result[1];
//       let users = result[2];
//       condition =
//         condition &&
//         (await condition.map((val) => {
//           val.createdBy = users.find((u) => u.userId == val.createdBy)
//             ? users.find((u) => u.userId == val.createdBy)
//             : {};
//           val.tasks = task.filter((t) => t.conditionId == val.conditionId);
//           val.tasks =
//             val.tasks &&
//             val.tasks.map((val1) => {
//               val1.dateToSort = val1.dueDate;

//               if (
//                 val1.type === appConstants.taskTypes.trackProgress ||
//                 val1.type === appConstants.taskTypes.treatment ||
//                 val1.type === appConstants.taskTypes.medication
//               ) {
//                 val1.taskPendingDates = val1.taskPendingDates || [];

//                 if (val1.taskPendingDates.length > 1) {
//                   let date = moment().format(appConstants.DATE_FORMAT);
//                   val1.taskPendingDates.sort((a, b) => {
//                     return new Date(a) - new Date(b);
//                   });
//                   if (
//                     val1.taskPendingDates.find(
//                       (a) => new Date(a) >= new Date(date)
//                     )
//                   ) {
//                     let dateToCompleteTheTask = val1.taskPendingDates.find(
//                       (a) => new Date(a) >= new Date(date)
//                     );
//                     if (dateToCompleteTheTask)
//                       val1.dateToSort = dateToCompleteTheTask;
//                   }
//                 }
//               }

//               val1.createdBy = users.find((u) => u.userId == val1.createdBy)
//                 ? users.find((u) => u.userId == val1.createdBy)
//                 : {};
//               console.log("val1:", val1.dateToSort);
//               return val1;
//             });
//           val.tasks = val.tasks.sort((a, b) => {
//             return new Date(a.dateToSort) - new Date(b.dateToSort);
//           });

//           return val;
//         }));
//       cb(null, condition);
//     }
//   );
// }

// export function getHealthNotes(patientId, cb) {
//   let conditionList = [];
//   let notesList = [];
//   async.parallel(
//     [
//       function (cb1) {
//         const conditionRef = firestoreOperationsDb.collection("conditions");
//         conditionRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .where("isCompleted", "==", false)
//           .get()
//           .then(async function (conditions) {
//             await conditions.forEach(async function (doc) {
//               let condition = doc.data();
//               condition.conditionId = doc.id;
//               conditionList.push(condition);
//             });
//             cb1(null, conditionList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const taskRef = firestoreOperationsDb.collection("healthNotes");
//         taskRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (notes) {
//             await notes.forEach(async function (doc) {
//               let note = doc.data();
//               note.noteId = doc.id;
//               notesList.push(note);
//             });
//             cb1(null, notesList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//     ],
//     async (error, result) => {
//       if (error) {
//         console.log("error:", error);
//         cb(error.message, null);
//       }
//       let condition = result[0];
//       let note = result[1];
//       let users = result[2];
//       condition =
//         condition &&
//         (await condition.map((val) => {
//           val.notes = note.filter((t) => t.conditionId == val.conditionId);
//           return val;
//         }));
//       cb(null, condition);
//     }
//   );
// }

// export async function getTeamMembers(clinicId, cb) {
//   let members = [];
//   console.log("members^^^^^^^", members);
//   let memberRef = await firestoreOperationsDb.collection("users");
//   memberRef
//     .where("isDeleted", "==", false)
//     .where("clinicId", "==", clinicId)

//     // if (clinicId) memberRef = memberRef.where("clinicId", "==", clinicId);

//     .get()
//     .then(async function (memberData) {
//       await memberData.forEach(async function (doc) {
//         let member = doc.data();
//         member.memberId = doc.id;
//         members.push(member);
//       });
//       cb(null, members);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export function getConditionTemplate(cb) {
//   let templateList = [];
//   const templateRef = firestoreMasterDb.collection("template");
//   templateRef
//     .where("isDeleted", "==", false)
//     .get()

//     .then(async function (templateData) {
//       await templateData.forEach(async function (doc) {
//         let template = doc.data();
//         template.templateId = doc.id;
//         templateList.push(template);
//       });
//       cb(null, templateList);
//       console.log("templateList", templateList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export function checkIFConditionExists(conditionName, patientId) {
//   return new Promise((resolve, reject) => {
//     const conditionRef = firestoreOperationsDb.collection("conditions");
//     conditionRef
//       .where("conditionToLowerCase", "==", conditionName.toLowerCase())
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .get()
//       .then(async function (conditions) {
//         resolve(conditions.size);
//       })
//       .catch(function (error) {
//         console.log("error:", error);
//         reject(error);
//       });
//   });
// }

// export function checkIFConditionExistsList(conditionName, patientId) {
//   return new Promise((resolve, reject) => {
//     const conditionRef = firestoreOperationsDb.collection("conditions");
//     conditionRef
//       .where("conditionToLowerCase", "==", conditionName.toLowerCase())
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .get()
//       .then(async function (conditions) {
//         let conditionList = [];
//         conditions.forEach((doc) => {
//           let con = doc.data();
//           con.conditionId = doc.id;
//           conditionList.push(con);
//         });
//         resolve(conditionList);
//       })
//       .catch(function (error) {
//         console.log("error:", error);
//         reject(error);
//       });
//   });
// }

// export function checkIFPatientExists(email, phoneNumber) {
//   let patients = [];
//   let patients1 = [];
//   let emailExists = false;
//   let phoneExists = false;

//   return new Promise(async (resolve, reject) => {
//     async.parallel(
//       [
//         function (cb1) {
//           let patientRef = firestoreOperationsDb
//             .collection("patients")
//             .where("email", "==", email)
//             .where("isDeleted", "==", false);

//           patientRef
//             .get()
//             .then(async function (patientRes) {
//               await patientRes.forEach(async function (doc) {
//                 let patient = doc.data();
//                 patient.patientId = doc.id;
//                 patients.push(patient);
//               });
//               cb1(null, patients);
//             })
//             .catch(function (error) {
//               console.log("error:", error);
//               cb1(error, null);
//             });
//         },
//         function (cb1) {
//           let patientRef = firestoreOperationsDb
//             .collection("patients")
//             .where("isDeleted", "==", false)
//             .where("phoneNumber", "==", phoneNumber);

//           patientRef
//             .get()
//             .then(async function (patientRes) {
//               await patientRes.forEach(async function (doc) {
//                 console.log("doc:", doc.data());
//                 patients1.push(doc.data());
//               });
//               cb1(null, patients1);
//             })
//             .catch(function (error) {
//               console.log("error:", error);
//               cb1(error, null);
//             });
//         },
//       ],
//       async (error, result) => {
//         if (error) {
//           console.log("error:", error);
//           reject(error);
//         }
//         result[0] = result[0].concat(result[1]);
//         result[0] = _.uniqBy(result[0], "userId");
//         console.log("result[0]", result[0]);
//         if (result[0] && result[0].length) {
//           if (
//             result[0].filter(
//               (p) => p.email === email && p.phoneNumber === phoneNumber
//             ).length
//           ) {
//             resolve({
//               patients: result[0],
//               emailExists: true,
//               phoneExists: true,
//             });
//           } else if (
//             result[0].filter(
//               (p) => p.email === email && p.phoneNumber !== phoneNumber
//             ).length
//           ) {
//             resolve({
//               patients: result[0],
//               emailExists: true,
//               phoneExists: false,
//             });
//           } else if (
//             result[0].filter(
//               (p) => p.phoneNumber === phoneNumber && p.email !== email
//             ).length
//           ) {
//             resolve({
//               patients: result[0],
//               emailExists: false,
//               phoneExists: true,
//             });
//           } else {
//             resolve({
//               patients: result[0],
//               emailExists: false,
//               phoneExists: false,
//             });
//           }
//         } else {
//           resolve({
//             patients: [],
//             emailExists: false,
//             phoneExists: false,
//           });
//         }
//       }
//     );
//   });

//   // return new Promise((resolve, reject) => {
//   //   const patientRef = firestoreOperationsDb.collection("patients");
//   //   patientRef
//   //     .where("email", "==", email)
//   //     .where("isDeleted", "==", false)
//   //     .get()
//   //     .then(async function (patientRes) {
//   //       await patientRes.forEach(async function (doc) {
//   //         let patient = doc.data();
//   //         patient.patientId = doc.id;
//   //         patients.push(patient);
//   //       });
//   //       resolve(patients);
//   //     })
//   //     .catch(function (error) {
//   //       console.log("error:", error);
//   //       reject(error);
//   //     });
//   // });
// }

// export function checkIFUserExists(email, phoneNumber, userId) {
//   let users = [],
//     users1 = [];
//   return new Promise(async (resolve, reject) => {
//     async.parallel(
//       [
//         function (cb1) {
//           let userRef = firestoreOperationsDb
//             .collection("users")
//             .where("isDeleted", "==", false)
//             .where("email", "==", email);

//           if (userId) userRef = userRef.where("userId", "!=", userId);
//           userRef
//             .get()
//             .then(async function (userRes) {
//               await userRes.forEach(async function (doc) {
//                 console.log("doc:", doc.data());
//                 users.push(doc.data());
//               });
//               cb1(null, users);
//             })
//             .catch(function (error) {
//               console.log("error:", error);
//               cb1(error, null);
//             });
//         },
//         function (cb1) {
//           let userRef = firestoreOperationsDb
//             .collection("users")
//             .where("isDeleted", "==", false)
//             .where("phoneNumber", "==", phoneNumber);

//           if (userId) userRef = userRef.where("userId", "!=", userId);
//           userRef
//             .get()
//             .then(async function (userRes) {
//               await userRes.forEach(async function (doc) {
//                 console.log("doc:", doc.data());
//                 users1.push(doc.data());
//               });
//               cb1(null, users1);
//             })
//             .catch(function (error) {
//               console.log("error:", error);
//               cb1(error, null);
//             });
//         },
//       ],
//       async (error, result) => {
//         if (error) {
//           console.log("error:", error);
//           reject(error);
//         }
//         result[0] = result[0].concat(result[1]);
//         resolve(result[0]);
//       }
//     );

//     let userRef = await firestoreOperationsDb
//       .collection("users")
//       .where("isDeleted", "==", false);
//     console.log("userId:", email, phoneNumber, userId);
//     if (email) userRef = userRef.where("email", "==", email);
//     if (phoneNumber) userRef = userRef.where("phoneNumber", "==", phoneNumber);
//     if (userId) userRef = userRef.where("userId", "!=", userId);
//     await userRef
//       .get()
//       .then(async function (userRes) {
//         await userRes.forEach(async function (doc) {
//           console.log("doc:", doc.data());
//           users.push(doc.data());
//         });
//         resolve(users);
//       })
//       .catch(function (error) {
//         console.log("error:", error);
//         reject(error);
//       });
//   });
// }

// export function identificationNumberExists(identificationNumber) {
//   return new Promise(async (resolve, reject) => {
//     let users = [];
//     firestoreOperationsDb
//       .collection("patients")
//       .where("isDeleted", "==", false)
//       .where("identificationNumber", "==", identificationNumber)
//       .get()
//       .then(async function (userRes) {
//         await userRes.forEach(async function (doc) {
//           console.log("doc:", doc.data());
//           users.push(doc.data());
//         });
//         resolve(users);
//       })
//       .catch(function (error) {
//         console.log("error:", error);
//         reject(error);
//       });
//   });
// }

// export async function getPatientViaCode(surName, careCode, cb) {
//   const patientRef = firestoreOperationsDb.collection("patients");
//   patientRef
//     .where("careCode", "==", careCode)
//     .where("isDeleted", "==", false)
//     .get()
//     .then(async function (patientRes) {
//       if (patientRes.size != 0) {
//         await patientRes.forEach(async function (doc) {
//           let patient = doc.data();
//           if (patient.surName.toLowerCase() == surName.toLowerCase()) {
//             cb(null, patient);
//           } else {
//             cb(responseMessages.INVALID_SURNAME, null);
//           }
//         });
//       } else {
//         cb(responseMessages.INVALID_CODE, null);
//       }
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function getDocumentTypes(cb) {
//   let typeList = [];
//   const typeRef = firestoreMasterDb.collection("documentTypes");
//   typeRef
//     .where("isDeleted", "==", false)
//     .get()
//     .then(async function (typeData) {
//       await typeData.forEach(async function (doc) {
//         let type = doc.data();
//         type.typeId = doc.id;
//         typeList.push(type);
//       });
//       cb(null, typeList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function getConditionsList(patientId, cb) {
//   let conditionList = [];
//   const conditionRef = firestoreOperationsDb.collection("conditions");
//   conditionRef
//     .where("patientId", "==", patientId)
//     .where("isDeleted", "==", false)
//     .get()
//     .then(async function (conditions) {
//       await conditions.forEach(async function (doc) {
//         let condition = doc.data();
//         condition.conditionId = doc.id;
//         conditionList.push(condition);
//       });
//       cb(null, conditionList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function getProgressData(patientId, date, cb) {
//   try {
//     console.log("date", date);
//     let haveStepGoalTaskData = [],
//       haveSleepGoalTaskData = [],
//       todaySleepCountProgressData = [],
//       haveCaloriesIntakeTaskData = [],
//       todayCaloriesIntakeProgressData = [],
//       todayStepCountProgressData = [],
//       haveBodyWeightTaskData = [],
//       bodyWeightTaskProgressData = [],
//       haveBMITaskData = [],
//       BMITaskProgressData = [],
//       haveTemperatureTaskData = [],
//       temperatureTaskProgressData = [],
//       havePainLevelTaskData = [],
//       painLevelProgressData = [],
//       haveBloodPressureTaskData = [],
//       bloodPressureProgressData = [],
//       haveBloodGlucoseTaskData = [],
//       bloodGlucoseProgressData = [];

//     let taskRef = firestoreOperationsDb.collection("tasks");
//     let taskProgressRef = firestoreOperationsDb.collection("stepCountProgress");
//     let sleepProgressRef = firestoreOperationsDb.collection("sleepProgress");
//     let bloodPressureProgressRef = firestoreOperationsDb.collection(
//       "bloodPressureProgress"
//     );

//     let bodyWeightProgressRef =
//       firestoreOperationsDb.collection("bodyWeightProgress");
//     let painLevelProgressRef =
//       firestoreOperationsDb.collection("painLevelProgress");
//     let temperatureProgressRef = firestoreOperationsDb.collection(
//       "temperatureProgress"
//     );
//     let calorieIntakeProgressRef = firestoreOperationsDb.collection(
//       "calorieIntakeProgress"
//     );
//     //For BMI Task
//     let bmiProgressRef = firestoreOperationsDb.collection("BMIProgress");

//     let haveBMITask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where("trackProgress.trackingType", "==", appConstants.trackingTypes.bmi)
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     haveBMITask.forEach((ele) => {
//       let doc = ele.data();
//       haveBMITaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let BMIProgress = await bmiProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("completedOn", "desc")
//       .limit(1)
//       .get();

//     BMIProgress.forEach((ele) => {
//       let doc = ele.data();
//       BMITaskProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     //For BloodGlucose Task

//     let bloodGlucoseProgressRef = firestoreOperationsDb.collection(
//       "bloodGlucoseProgress"
//     );

//     let haveBloodGlucoseTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.bloodGlucose
//       )
//       .limit(1)
//       .get();

//     haveBloodGlucoseTask.forEach((ele) => {
//       let doc = ele.data();
//       console.log("Paint lavele donc", doc);
//       haveBloodGlucoseTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let dayss = getCurrentWeek();

//     let bloodGlucoseProgress = await bloodGlucoseProgressRef
//       .where("patientId", "==", patientId)
//       .where("date", ">=", dayss[0])
//       .where("date", "<=", dayss[dayss.length - 1])
//       .get();

//     bloodGlucoseProgress.forEach((ele) => {
//       let doc = ele.data();
//       bloodGlucoseProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     dayss = dayss.map((date) => {
//       let obj = {
//         date: date,
//         painLevel: 0,
//         color: "#dfdfdf",
//       };
//       let found = bloodGlucoseProgressData.find((e) => e.date === date);
//       if (found) {
//         obj.painLevel = found.input;
//         obj.color = "#12cae6";
//       }
//       return obj;
//     });

//     //For Sleep Progress
//     let haveSleepTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.sleep
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     let todaySleepTask = await sleepProgressRef
//       .where("patientId", "==", patientId)
//       .where("date", "==", date)
//       .limit(1)
//       .get();

//     haveSleepTask.forEach((ele) => {
//       let doc = ele.data();
//       haveSleepGoalTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todaySleepTask.forEach((ele) => {
//       let doc = ele.data();
//       todaySleepCountProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     //For StepCount Progress
//     let haveStepGoalTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.stepCount
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     let todayStepCountProgress = await taskProgressRef
//       .where("patientId", "==", patientId)
//       .where("date", "==", date)
//       .limit(1)
//       .get();

//     haveStepGoalTask.forEach((ele) => {
//       let doc = ele.data();
//       haveStepGoalTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayStepCountProgress.forEach((ele) => {
//       let doc = ele.data();
//       todayStepCountProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     // FOR CALORIE INTAKE
//     let haveCaloriesIntake = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.caloricIntake
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     let todayCalorieIntakeProgress = await calorieIntakeProgressRef
//       .where("patientId", "==", patientId)
//       .where("date", "==", date)
//       .limit(1)
//       .get();

//     haveCaloriesIntake.forEach((ele) => {
//       let doc = ele.data();
//       haveCaloriesIntakeTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayCalorieIntakeProgress.forEach((ele) => {
//       let doc = ele.data();
//       todayCaloriesIntakeProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     //For Temperature Task
//     let haveTemperatureTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.temperature
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     haveTemperatureTask.forEach((ele) => {
//       let doc = ele.data();
//       haveTemperatureTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let bodyTemperatureProgress = await temperatureProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("completedOn", "desc")
//       .limit(1)
//       .get();

//     bodyTemperatureProgress.forEach((ele) => {
//       let doc = ele.data();
//       temperatureTaskProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });
//     //For BodyWight Task
//     let havebodyWeightTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.bodyWeight
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     havebodyWeightTask.forEach((ele) => {
//       let doc = ele.data();
//       haveBodyWeightTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let bodyWeightProgress = await bodyWeightProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("completedOn", "desc")
//       .limit(1)
//       .get();

//     bodyWeightProgress.forEach((ele) => {
//       let doc = ele.data();
//       bodyWeightTaskProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     // bloodPressureProgressRef
//     let haveBloodPressureTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.bloodPressure
//       )
//       .limit(1)
//       .get();

//     haveBloodPressureTask.forEach((ele) => {
//       let doc = ele.data();
//       console.log("Paint lavele donc", doc);
//       haveBloodPressureTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let daysss = getCurrentWeek();

//     let bloodPressureProgress = await bloodPressureProgressRef
//       .where("patientId", "==", patientId)
//       .where("date", ">=", daysss[0])
//       .where("date", "<=", daysss[daysss.length - 1])
//       .get();

//     bloodPressureProgress.forEach((ele) => {
//       let doc = ele.data();
//       bloodPressureProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     daysss = daysss.map((date) => {
//       let obj = {
//         date: date,
//         min: 0,
//         max: 0,
//         color: "#dfdfdf",
//       };
//       let found = bloodPressureProgressData.find((e) => e.date === date);
//       if (found) {
//         obj.max = found.max;
//         obj.min = found.min;
//         obj.color = "#12cae6";
//       }
//       return obj;
//     });
//     //For PainLevel Task
//     let havePainLevelTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.painLevel
//       )
//       .limit(1)
//       .get();

//     havePainLevelTask.forEach((ele) => {
//       let doc = ele.data();
//       console.log("Paint lavele donc", doc);
//       havePainLevelTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let days = getCurrentWeek();

//     let painLevelProgress = await painLevelProgressRef
//       .where("patientId", "==", patientId)
//       .where("date", ">=", days[0])
//       .where("date", "<=", days[days.length - 1])
//       .get();

//     painLevelProgress.forEach((ele) => {
//       let doc = ele.data();
//       painLevelProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     days = days.map((date) => {
//       let obj = {
//         date: date,
//         painLevel: 0,
//         color: "#dfdfdf",
//       };
//       let found = painLevelProgressData.find((e) => e.date === date);
//       if (found) {
//         obj.painLevel = found.input;
//         obj.color = "#12cae6";
//       }
//       return obj;
//     });

//     //Medication task
//     let medicationComplainent = 0,
//       haveMedicationTaskData = [];

//     let haveMedicationTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where("type", "==", appConstants.taskTypes.medication)
//       .get();

//     haveMedicationTask.forEach((ele) => {
//       let doc = ele.data();
//       haveMedicationTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//       if (doc.compliance) {
//         medicationComplainent += doc.compliance;
//       }
//     });

//     //Media Galary task
//     let haveTreatmentTyepTaskData = [];

//     let haveTreatmentTyepTasK = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where("type", "==", appConstants.taskTypes.treatment)
//       .limit(1)
//       .get();

//     haveTreatmentTyepTasK.forEach((ele) => {
//       let doc = ele.data();
//       haveTreatmentTyepTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     cb(null, {
//       haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
//       stepGoalTaskObject: haveStepGoalTaskData[0]
//         ? haveStepGoalTaskData[0]
//         : {},
//       todayStepCount:
//         todayStepCountProgressData.length > 0
//           ? todayStepCountProgressData[0].input
//           : 0,

//       haveSleepTask: haveSleepGoalTaskData.length > 0 ? true : false,
//       sleepGoalTaskObject: haveSleepGoalTaskData[0]
//         ? haveSleepGoalTaskData[0]
//         : {},
//       todaySleepCount:
//         todaySleepCountProgressData.length > 0
//           ? todaySleepCountProgressData[0].input
//           : 0,

//       haveCaloriesIntakeTask:
//         haveCaloriesIntakeTaskData.length > 0 ? true : false,
//       caloriesIntakeTaskObject: haveCaloriesIntakeTaskData[0]
//         ? haveCaloriesIntakeTaskData[0]
//         : {},
//       todayCaloriesIntakeCount:
//         todayCaloriesIntakeProgressData.length > 0
//           ? todayCaloriesIntakeProgressData[0].input
//           : 0,
//       // haveTemperatureTaskData = [],
//       // temperatureTaskProgressData = [],
//       haveTemperatureTask: haveTemperatureTaskData.length > 0 ? true : false,
//       bodyTemperatureTaskObject: haveTemperatureTaskData[0]
//         ? haveTemperatureTaskData[0]
//         : {},
//       currentTemperature:
//         temperatureTaskProgressData.length > 0
//           ? temperatureTaskProgressData[0].input
//           : haveTemperatureTaskData[0]
//           ? haveTemperatureTaskData[0].trackProgress &&
//             haveTemperatureTaskData[0].trackProgress.currentWeight
//           : 0,

//       haveBMITask: haveBMITaskData.length > 0 ? true : false,
//       BMITaskObject: haveBMITaskData[0] ? haveBMITaskData[0] : {},
//       currentBMI:
//         BMITaskProgressData.length > 0
//           ? BMITaskProgressData[0].input
//           : haveBMITaskData[0]
//           ? haveBMITaskData[0].trackProgress &&
//             haveBMITaskData[0].trackProgress.currentWeight
//           : 0,

//       havebodyWeightTask: haveBodyWeightTaskData.length > 0 ? true : false,
//       bodyWeightTaskObject: haveBodyWeightTaskData[0]
//         ? haveBodyWeightTaskData[0]
//         : {},
//       currentWeight:
//         bodyWeightTaskProgressData.length > 0
//           ? bodyWeightTaskProgressData[0].input
//           : haveBodyWeightTaskData[0]
//           ? haveBodyWeightTaskData[0].trackProgress &&
//             haveBodyWeightTaskData[0].trackProgress.currentWeight
//           : 0,

//       haveBloodPressureTask:
//         haveBloodPressureTaskData.length > 0 ? true : false,
//       bloodPressureProgress: daysss,

//       havePainLevelTask: havePainLevelTaskData.length > 0 ? true : false,
//       painLevelProgress: days,

//       haveBloodGlucoseTask: haveBloodGlucoseTaskData.length > 0 ? true : false,
//       bloodGlucoseProgress: dayss,

//       haveMedicationTask: haveMedicationTaskData.length > 0 ? true : false,
//       medicationComplainent,
//       medicationTaskDate: haveMedicationTaskData,

//       haveTreatmentTask: haveTreatmentTyepTaskData.length > 0 ? true : false,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getStepCountModalData(patientId, start, end, cb) {
//   try {
//     let haveStepGoalTaskData = [],
//       todayStepCountProgressData = [],
//       stepCountHistoryData = [];

//     let taskRef = firestoreOperationsDb.collection("tasks");
//     let taskProgressRef = firestoreOperationsDb.collection("stepCountProgress");

//     let haveStepGoalTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.stepCount
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     let todayStepCountProgress = await taskProgressRef
//       .where("patientId", "==", patientId)
//       // .where("date", "==", date)
//       .where("completedOn", ">", new Date(start))
//       .where("completedOn", "<", new Date(end))
//       // .limit(1)      .limit(1)
//       .get();

//     haveStepGoalTask.forEach((ele) => {
//       let doc = ele.data();
//       haveStepGoalTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayStepCountProgress.forEach((ele) => {
//       let doc = ele.data();
//       todayStepCountProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let stepCountHistory = await taskProgressRef
//       .where("patientId", "==", patientId)
//       .get();

//     stepCountHistory.forEach((ele) => {
//       let doc = ele.data();
//       stepCountHistoryData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayStepCountProgressData = todayStepCountProgressData.filter(
//       (thing, index, self) =>
//         index ===
//         self.findIndex((t) => t.date === thing.date && t.input >= thing.input)
//     );
//     let total = 0;
//     todayStepCountProgressData.map((e) => {
//       total = total + e.input;
//     });
//     let average = total / todayStepCountProgressData.length;

//     cb(null, {
//       haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
//       stepGoalTaskObject: haveStepGoalTaskData[0]
//         ? haveStepGoalTaskData[0]
//         : {},
//       todayStepCount:
//         todayStepCountProgressData.length > 0 ? Math.floor(average) : 0,
//       stepCountHistoryData: stepCountHistoryData,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getSleepCountModalData(patientId, start, end, cb) {
//   try {
//     let haveStepGoalTaskData = [],
//       todayStepCountProgressData = [],
//       stepCountHistoryData = [];

//     let taskRef = firestoreOperationsDb.collection("tasks");
//     let taskProgressRef = firestoreOperationsDb.collection("sleepProgress");

//     let haveStepGoalTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.sleep
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     let todayStepCountProgress = await taskProgressRef
//       .where("patientId", "==", patientId)
//       // .where("date", "==", date)
//       .where("completedOn", ">", new Date(start))
//       .where("completedOn", "<", new Date(end))
//       // .limit(1)
//       .get();

//     haveStepGoalTask.forEach((ele) => {
//       let doc = ele.data();
//       haveStepGoalTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayStepCountProgress.forEach((ele) => {
//       let doc = ele.data();
//       todayStepCountProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let stepCountHistory = await taskProgressRef
//       .where("patientId", "==", patientId)
//       .get();

//     stepCountHistory.forEach((ele) => {
//       let doc = ele.data();
//       stepCountHistoryData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });
//     todayStepCountProgressData = todayStepCountProgressData.filter(
//       (thing, index, self) =>
//         index ===
//         self.findIndex((t) => t.date === thing.date && t.input >= thing.input)
//     );
//     let total = 0;
//     todayStepCountProgressData.map((e) => {
//       total = total + e.input;
//     });
//     let average = total / todayStepCountProgressData.length;

//     cb(null, {
//       haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
//       stepGoalTaskObject: haveStepGoalTaskData[0]
//         ? haveStepGoalTaskData[0]
//         : {},
//       todayStepCount:
//         todayStepCountProgressData.length > 0 ? Math.floor(average) : 0,
//       stepCountHistoryData: stepCountHistoryData,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getCalorieIntaketModalData(patientId, start, end, cb) {
//   try {
//     let haveStepGoalTaskData = [],
//       todayStepCountProgressData = [],
//       stepCountHistoryData = [];

//     let taskRef = firestoreOperationsDb.collection("tasks");
//     let taskProgressRef = firestoreOperationsDb.collection(
//       "calorieIntakeProgress"
//     );

//     let haveStepGoalTask = await taskRef
//       .where("patientId", "==", patientId)
//       .where("isDeleted", "==", false)
//       .where("status", "==", appConstants.status.active.name)
//       .where(
//         "trackProgress.trackingType",
//         "==",
//         appConstants.trackingTypes.caloricIntake
//       )
//       .orderBy("dueDate", "desc")
//       .limit(1)
//       .get();

//     let todayStepCountProgress = await taskProgressRef
//       .where("patientId", "==", patientId)
//       // .where("date", "==", date)
//       .where("completedOn", ">", new Date(start))
//       .where("completedOn", "<", new Date(end))
//       // .limit(1)      .limit(1)
//       .get();

//     haveStepGoalTask.forEach((ele) => {
//       let doc = ele.data();
//       haveStepGoalTaskData.push(
//         Object.assign(
//           {
//             taskId: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayStepCountProgress.forEach((ele) => {
//       let doc = ele.data();
//       todayStepCountProgressData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let stepCountHistory = await taskProgressRef
//       .where("patientId", "==", patientId)
//       .get();

//     stepCountHistory.forEach((ele) => {
//       let doc = ele.data();
//       stepCountHistoryData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     todayStepCountProgressData = todayStepCountProgressData.filter(
//       (thing, index, self) =>
//         index ===
//         self.findIndex((t) => t.date === thing.date && t.input >= thing.input)
//     );
//     let total = 0;
//     todayStepCountProgressData.map((e) => {
//       total = total + e.input;
//     });
//     let average = total / todayStepCountProgressData.length;

//     cb(null, {
//       haveStepGoalTask: haveStepGoalTaskData.length > 0 ? true : false,
//       stepGoalTaskObject: haveStepGoalTaskData[0]
//         ? haveStepGoalTaskData[0]
//         : {},
//       todayStepCount:
//         todayStepCountProgressData.length > 0 ? Math.floor(average) : 0,
//       stepCountHistoryData: stepCountHistoryData,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getMediaDataData(patientId, cb) {
//   try {
//     let mediaData = [];
//     let treatmentProgressRef =
//       firestoreOperationsDb.collection("treatmentProgress");

//     let treatmentProgress = await treatmentProgressRef
//       .where("patientId", "==", patientId)
//       .get();

//     await asyncForEachPromise(treatmentProgress, async (ele) => {
//       let doc = ele.data();

//       let taskData = await doc.taskIdRef.get();
//       let conditionData = await doc.conditionIdRef.get();

//       doc.complationDate = doc.completedOn.toDate();

//       doc.taskData = taskData.data();
//       doc.conditionData = conditionData.data();
//       mediaData.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     let uniqueConditionIds = [
//       ...new Set(mediaData.map((obj) => obj.conditionId)),
//     ];

//     uniqueConditionIds = uniqueConditionIds.map((id) => {
//       let tasks = mediaData.filter((e) => e.conditionId == id);
//       return {
//         conditionName:
//           tasks.length > 0
//             ? (tasks[0].conditionData &&
//                 tasks[0].conditionData.conditionName) ||
//               ""
//             : "",
//         conditionId: id,
//         tasks,
//       };
//     });
//     cb(null, {
//       mediaData: uniqueConditionIds,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getBodyWeightModalData(patientId, cb) {
//   try {
//     let data = [];
//     let bodyWeightProgressRef =
//       firestoreOperationsDb.collection("bodyWeightProgress");

//     let bodyWeightProgress = await bodyWeightProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     await asyncForEachPromise(bodyWeightProgress, async (ele) => {
//       let doc = ele.data();

//       if (doc.recordedByRef) {
//         let recorderByData = await doc.recordedByRef.get();
//         doc.recorderByData = recorderByData.data();
//       }
//       doc.complationDate = doc.completedOn.toDate();

//       data.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     cb(null, {
//       data,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getBodyTemperatureModalData(patientId, cb) {
//   try {
//     let data = [];
//     let bodyWeightProgressRef = firestoreOperationsDb.collection(
//       "temperatureProgress"
//     );

//     let bodyWeightProgress = await bodyWeightProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     await asyncForEachPromise(bodyWeightProgress, async (ele) => {
//       let doc = ele.data();

//       if (doc.recordedByRef) {
//         let recorderByData = await doc.recordedByRef.get();
//         doc.recorderByData = recorderByData.data();
//       }
//       doc.complationDate = doc.completedOn.toDate();

//       data.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     cb(null, {
//       data,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getBMIModalData(patientId, cb) {
//   try {
//     let data = [];
//     let bodyWeightProgressRef = firestoreOperationsDb.collection("BMIProgress");

//     let bodyWeightProgress = await bodyWeightProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     await asyncForEachPromise(bodyWeightProgress, async (ele) => {
//       let doc = ele.data();

//       if (doc.recordedByRef) {
//         let recorderByData = await doc.recordedByRef.get();
//         doc.recorderByData = recorderByData.data();
//       }
//       doc.complationDate = doc.completedOn.toDate();

//       data.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     cb(null, {
//       data,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getMedicationModalData(patientId, cb) {
//   try {
//     let data = [];
//     let medicationProgressRef =
//       firestoreOperationsDb.collection("medicationProgress");

//     let bodyWeightProgress = await medicationProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     await asyncForEachPromise(bodyWeightProgress, async (ele) => {
//       let doc = ele.data();

//       if (doc.recordedByRef) {
//         let recorderByData = await doc.recordedByRef.get();
//         doc.recorderByData = recorderByData.data();
//       }
//       doc.complationDate = doc.completedOn.toDate();

//       data.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     cb(null, {
//       data,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getPainLevelModalData(patientId, cb) {
//   try {
//     let data = [];
//     let painLevelProgressRef =
//       firestoreOperationsDb.collection("painLevelProgress");

//     let bodyWeightProgress = await painLevelProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     bodyWeightProgress.forEach((obj) => {
//       data.push(
//         Object.assign(
//           {
//             _id: obj.id,
//           },
//           obj.data()
//         )
//       );
//     });

//     let daysOfWeek = getCurrentWeek();
//     daysOfWeek = daysOfWeek.map((date) => {
//       let found = data.find((e) => {
//         return e.date === date;
//       });
//       return {
//         date: date,
//         painLevel: found ? Number(found.input) : 0,
//         color: found ? "#12cae6" : "#dfdfdf",
//       };
//     });

//     cb(null, {
//       data,
//       currentPainLevel: data[0] ? Number(data[0].input) : 0,
//       daysOfWeek,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getBloodGlucoseModalData(patientId, cb) {
//   try {
//     let data = [];
//     let painLevelProgressRef = firestoreOperationsDb.collection(
//       "bloodGlucoseProgress"
//     );

//     let bodyWeightProgress = await painLevelProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     bodyWeightProgress.forEach((obj) => {
//       data.push(
//         Object.assign(
//           {
//             _id: obj.id,
//           },
//           obj.data()
//         )
//       );
//     });

//     let daysOfWeek = getCurrentWeek();
//     daysOfWeek = daysOfWeek.map((date) => {
//       let found = data.find((e) => {
//         return e.date === date;
//       });
//       return {
//         date: date,
//         painLevel: found ? Number(found.input) : 0,
//         color: found ? "#12cae6" : "#dfdfdf",
//       };
//     });

//     cb(null, {
//       data,
//       currentPainLevel: data[0] ? Number(data[0].input) : 0,
//       daysOfWeek,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getBloodPressureModalData(patientId, cb) {
//   try {
//     let data = [];
//     let painLevelProgressRef = firestoreOperationsDb.collection(
//       "bloodPressureProgress"
//     );

//     let bodyWeightProgress = await painLevelProgressRef
//       .where("patientId", "==", patientId)
//       .orderBy("date", "desc")
//       .get();

//     bodyWeightProgress.forEach((obj) => {
//       data.push(
//         Object.assign(
//           {
//             _id: obj.id,
//           },
//           obj.data()
//         )
//       );
//     });

//     console.log("datatatatat", data);

//     let daysOfWeek = getCurrentWeek();
//     daysOfWeek = daysOfWeek.map((date) => {
//       let found = data.find((e) => {
//         return e.date === date;
//       });
//       // console.log("found",found)
//       return {
//         date: date,
//         max: found ? Number(found.max) : 0,
//         min: found ? Number(found.min) : 0,
//         color: found ? "#12cae6" : "#dfdfdf",
//       };
//     });
//     // console.log("daysOfW/eek",daysOfWeek);
//     cb(null, {
//       data,
//       currentMinBloodPressureLevel: data[0] ? Number(data[0].min) : 0,
//       currentMaxBloodPressureLevel: data[0] ? Number(data[0].max) : 0,
//       daysOfWeek,
//     });
//   } catch (error) {
//     console.log("error", error);
//     cb(error.message, null);
//   }
// }

// export async function getDocumentsList(patientId, cb) {
//   let documentList = [];
//   let userList = [];
//   let conditionList = [];

//   async.parallel(
//     [
//       function (cb1) {
//         const docRef = firestoreOperationsDb.collection("documents");
//         docRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (documents) {
//             await documents.forEach(async function (doc) {
//               let document = doc.data();
//               document.documentId = doc.id;
//               documentList.push(document);
//             });
//             cb1(null, documentList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const userRef = firestoreOperationsDb.collection("users");
//         userRef
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (users) {
//             await users.forEach(async function (doc) {
//               userList.push(doc.data());
//             });
//             cb1(null, userList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const conditionRef = firestoreOperationsDb.collection("conditions");
//         conditionRef
//           .where("isDeleted", "==", false)
//           .where("patientId", "==", patientId)
//           .get()
//           .then(async function (conditions) {
//             await conditions.forEach(async function (doc) {
//               let condition = doc.data();
//               condition.conditionId = doc.id;
//               conditionList.push(condition);
//             });
//             cb1(null, conditionList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//     ],
//     async (error, result) => {
//       if (error) {
//         console.log("error:", error);
//         cb(error.message, null);
//       }
//       let documents = result[0];
//       let users = result[1];
//       let conditions = result[2];
//       documents =
//         documents &&
//         (await documents.map((val) => {
//           val.uploadedBy = users.find((u) => u.userId == val.uploadedBy)
//             ? users.find((u) => u.userId == val.uploadedBy)
//             : {};
//           val.conditionId = conditions.find(
//             (c) => c.conditionId == val.conditionId
//           )
//             ? conditions.find((c) => c.conditionId == val.conditionId)
//             : {};
//           return val;
//         }));
//       cb(null, documents);
//     }
//   );
// }

// export async function getCompletedConditionList(patientId, cb) {
//   let conditionList = [];
//   const conditionRef = firestoreOperationsDb.collection("conditions");
//   conditionRef
//     .where("patientId", "==", patientId)
//     .where("isDeleted", "==", false)
//     .where("isCompleted", "==", true)
//     .get()
//     .then(async function (conditions) {
//       await conditions.forEach(async function (doc) {
//         let condition = doc.data();
//         condition.conditionId = doc.id;
//         conditionList.push(condition);
//       });
//       cb(null, conditionList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function getCareTeam(patientId, cb) {
//   let teamList = [];
//   let userList = [];
//   async.parallel(
//     [
//       function (cb1) {
//         const teamRef = firestoreOperationsDb.collection("careTeam");
//         teamRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (careTeam) {
//             await careTeam.forEach(async function (doc) {
//               let team = doc.data();
//               team.teamId = doc.id;
//               teamList.push(team);
//             });
//             cb1(null, teamList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const userRef = firestoreOperationsDb.collection("users");
//         userRef
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (users) {
//             await users.forEach(async function (doc) {
//               userList.push(doc.data());
//             });
//             cb1(null, userList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//     ],
//     async (error, result) => {
//       if (error) {
//         console.log("error:", error);
//         cb(error.message, null);
//       }
//       let careTeamList = [];
//       let careTeam = result[0];
//       let users = result[1];

//       careTeam &&
//         (await careTeam.map(async (val) => {
//           val.userId = users.find((u) => u.userId == val.userId)
//             ? users.find((u) => u.userId == val.userId)
//             : {};
//           val.conditions &&
//             (await val.conditions.map((val1) => {
//               let obj = { ...val, ...val1 };
//               delete obj.conditions;
//               careTeamList.push(obj);
//             }));
//         }));
//       careTeamList = _.orderBy(careTeamList, ["type"], ["desc"]);
//       careTeamList = careTeamList.filter((e, i) => {
//         return (
//           careTeamList.findIndex((x) => {
//             return x.userId === e.userId && x.conditionId === e.conditionId;
//           }) == i
//         );
//       });
//       cb(null, careTeamList);
//     }
//   );
// }

// export async function getCareTeamForAll(cb) {
//   let teamList = [];
//   let userList = [];
//   async.parallel(
//     [
//       function (cb1) {
//         const teamRef = firestoreOperationsDb.collection("careTeam");
//         teamRef
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (careTeam) {
//             await careTeam.forEach(async function (doc) {
//               let team = doc.data();
//               team.teamId = doc.id;
//               teamList.push(team);
//             });
//             cb1(null, teamList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const userRef = firestoreOperationsDb.collection("users");
//         userRef
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (users) {
//             await users.forEach(async function (doc) {
//               userList.push(doc.data());
//             });
//             cb1(null, userList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//     ],
//     async (error, result) => {
//       if (error) {
//         console.log("error:", error);
//         cb(error.message, null);
//       }
//       let careTeam = result[0];
//       let users = result[1];

//       careTeam =
//         careTeam &&
//         (await careTeam.map((val) => {
//           val.userId = users.find((u) => u.userId == val.userId)
//             ? users.find((u) => u.userId == val.userId)
//             : {};
//           return val;
//         }));
//       careTeam = careTeam.filter((e, i) => {
//         return (
//           careTeam.findIndex((x) => {
//             return x.userId === e.userId && x.patientId === e.patientId;
//           }) == i
//         );
//       });
//       cb(null, careTeam);
//     }
//   );
// }

// export async function getTasksList(patientId, cb) {
//   let taskList = [];
//   const taskRef = firestoreOperationsDb.collection("tasks");
//   taskRef
//     .where("patientId", "==", patientId)
//     .where("isDeleted", "==", false)
//     .get()
//     .then(async function (tasks) {
//       await tasks.forEach(async function (doc) {
//         let task = doc.data();
//         task.taskId = doc.id;
//         taskList.push(task);
//       });
//       cb(null, taskList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function getCompletedTasks(patientId, cb) {
//   let taskList = [];
//   let stepCountList = [];
//   let bodyWeightList = [];
//   let painLevelList = [];
//   let medicationList = [];
//   let treatmentList = [];

//   async.parallel(
//     [
//       function (cb1) {
//         const taskRef = firestoreOperationsDb.collection("tasks");
//         taskRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.taskId = doc.id;
//               taskList.push(task);
//             });
//             cb1(null, taskList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const stepCountRef =
//           firestoreOperationsDb.collection("stepCountProgress");
//         stepCountRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.recordId = doc.id;
//               stepCountList.push(task);
//             });
//             cb1(null, stepCountList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const bodyWeightRef =
//           firestoreOperationsDb.collection("bodyWeightProgress");
//         bodyWeightRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.recordId = doc.id;
//               bodyWeightList.push(task);
//             });
//             cb1(null, bodyWeightList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const treatmentRef =
//           firestoreOperationsDb.collection("treatmentProgress");
//         treatmentRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.recordId = doc.id;
//               treatmentList.push(task);
//             });
//             cb1(null, treatmentList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const painLevelRef =
//           firestoreOperationsDb.collection("painLevelProgress");
//         painLevelRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.recordId = doc.id;
//               painLevelList.push(task);
//             });
//             cb1(null, painLevelList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//       function (cb1) {
//         const medicationRef =
//           firestoreOperationsDb.collection("medicationProgress");
//         medicationRef
//           .where("patientId", "==", patientId)
//           .where("isDeleted", "==", false)
//           .get()
//           .then(async function (tasks) {
//             await tasks.forEach(async function (doc) {
//               let task = doc.data();
//               task.recordId = doc.id;
//               medicationList.push(task);
//             });
//             cb1(null, medicationList);
//           })
//           .catch(function (error) {
//             console.log("error:", error);
//             cb1(error.message, null);
//           });
//       },
//     ],
//     async (error, result) => {
//       if (error) {
//         console.log("error:", error);
//         cb(error.message, null);
//       }
//       let progressObj = {};
//       progressObj.taskList = result[0];
//       progressObj.stepCountList = result[1];
//       progressObj.bodyWeightList = result[2];
//       progressObj.treatmentList = result[3];
//       progressObj.painLevelList = result[4];
//       progressObj.medicationList = result[5];

//       cb(null, progressObj);
//     }
//   );
// }

// export async function getCountryList(cb) {
//   return fetch(`${appConstants.firebaseFunctionsUrl}getCountryData`)
//     .then((res) => res.json())
//     .then(
//       (result) => {
//         cb(null, result.data);
//       },
//       (error) => {
//         console.log("Error:", error);
//         cb(error.message, null);
//       }
//     );
// }

// export async function getMedicines(searchOptions, cb) {
//   // index
//   //   .search(searchString, searchOptions)
//   //   .then(function (responses) {
//   //     console.log("res:", responses);
//   //     cb(null, responses);
//   //   })
//   //   .catch(function (error) {
//   //     console.log("error:", error);
//   //     cb(error.message, null);
//   //   });
//   fetch(
//     `${appConstants.firebaseFunctionsUrl}getMedicineDataAPI?` +
//       new URLSearchParams(searchOptions)
//   )
//     .then((res) => res.json())
//     .then(
//       (result) => {
//         cb(null, result);
//       },
//       (error) => {
//         console.log("Error:", error);
//         cb(error.message, null);
//       }
//     );
// }

// export async function getSubscriptionPlans(cb) {
//   let planList = [];
//   const typeRef = firestoreMasterDb.collection("subscriptionPlans");
//   typeRef
//     .orderBy("priority")
//     .get()
//     .then(async function (planData) {
//       await planData.forEach(async function (doc) {
//         let plan = doc.data();
//         plan.planId = doc.id;
//         planList.push(plan);
//       });
//       cb(null, planList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function getActivitiesData(options, cb) {
//   try {
//     let activityDataToSend = [];
//     let activityRef = firestoreOperationsDb
//       .collection(`activities/${options.patientId}/${options.userType}`)
//       .orderBy("recordedAt", "desc")
//       .limit(options.limit);

//     if (options.lastVisible) {
//       activityRef = activityRef.startAfter(options.lastVisible);
//     }

//     let activityData = await activityRef.get();

//     await asyncForEachPromise(activityData, async (ele) => {
//       let doc = ele.data();

//       if (doc.recordedByRef) {
//         let recorderByData = await doc.recordedByRef.get();
//         doc.recorderByData = recorderByData.data();
//       }
//       activityDataToSend.push(
//         Object.assign(
//           {
//             id: ele.id,
//           },
//           doc
//         )
//       );
//     });

//     cb(null, {
//       data: activityDataToSend,
//       lastVisible: activityData.docs[activityData.docs.length - 1],
//     });
//   } catch (error) {
//     console.log("error fetching records", error);
//     cb(error.message, null);
//   }
// }

// export async function updateTooltipInfo(updates, clinicId, cb) {
//   try {
//     const clinicRef = firestoreOperationsDb.collection("clinics").doc(clinicId);
//     await clinicRef.update(updates);
//     cb(null, true);
//   } catch (error) {
//     console.log("[Error Update tooltipifo]", error);
//     cb(error.message, false);
//   }
// }

// export async function getDocumentLibrary(cb) {
//   let docList = [];
//   const typeRef = firestoreMasterDb.collection("documentLibrary");
//   typeRef
//     .get()
//     .then(async function (docData) {
//       await docData.forEach(async function (doc) {
//         let document = doc.data();
//         document.documentId = doc.id;
//         document.value = doc.id;
//         document.label = document.name;
//         docList.push(document);
//       });
//       cb(null, docList);
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export function checkIFUserExistsV1(email, phoneNumber, userId) {
//   let users = [],
//     users1 = [];
//   return new Promise(async (resolve, reject) => {
//     async.parallel(
//       [
//         function (cb1) {
//           let userRef = firestoreOperationsDb
//             .collection("users")
//             .where("isDeleted", "==", false)
//             .where("email", "==", email);

//           if (userId) userRef = userRef.where("userId", "!=", userId);
//           userRef
//             .get()
//             .then(async function (userRes) {
//               await userRes.forEach(async function (doc) {
//                 console.log("doc:", doc.data());
//                 users.push(doc.data());
//               });
//               cb1(null, users);
//             })
//             .catch(function (error) {
//               console.log("error:", error);
//               cb1(error, null);
//             });
//         },
//         function (cb1) {
//           let userRef = firestoreOperationsDb
//             .collection("users")
//             .where("isDeleted", "==", false)
//             .where("phoneNumber", "==", phoneNumber);

//           if (userId) userRef = userRef.where("userId", "!=", userId);
//           userRef
//             .get()
//             .then(async function (userRes) {
//               await userRes.forEach(async function (doc) {
//                 console.log("doc:", doc.data());
//                 users1.push(doc.data());
//               });
//               cb1(null, users1);
//             })
//             .catch(function (error) {
//               console.log("error:", error);
//               cb1(error, null);
//             });
//         },
//       ],
//       async (error, result) => {
//         if (error) {
//           console.log("error:", error);
//           reject(error);
//         }
//         if (result[0] && result[0].length) {
//           resolve({ status: true, message: responseMessages.EMAIL_EXISTS });
//         } else if (result[1] && result[1].length) {
//           resolve({ status: true, message: responseMessages.PHONE_EXISTS });
//         } else {
//           resolve({ status: false });
//         }
//       }
//     );
//   });
// }

// export async function getNotifications(options, cb) {
//   try {
//     let notificationDataToSend = [];
//     let notificationRef = firestoreOperationsDb
//       .collection(`notifications`)
//       .where("userId", "==", options.userId)
//       .orderBy("sentAt", "desc")
//       .limit(options.limit);

//     if (options.lastVisible) {
//       notificationRef = notificationRef.startAfter(options.lastVisible);
//     }

//     let notificationData = await notificationRef.get();

//     await asyncForEachPromise(notificationData, async (ele) => {
//       let doc = ele.data();

//       if (doc.taskRef) {
//         let taskData = await doc.taskRef.get();
//         doc.taskData = taskData.data();
//       }

//       notificationDataToSend.push(Object.assign({ id: ele.id }, doc));
//     });

//     cb(null, {
//       data: notificationDataToSend,
//       lastVisible: notificationData.docs[notificationData.docs.length - 1],
//     });
//   } catch (error) {
//     console.log("error fetching records", error);
//     cb(error.message, null);
//   }
// }

// export async function getUnreadNotificationCount(options, cb) {
//   try {
//     console.log("options", options);
//     let notificationRef;
//     if (options.notificationViewedAt) {
//       notificationRef = await firestoreOperationsDb
//         .collection(`notifications`)
//         .where("userId", "==", options.userId)
//         .where("addedOnTimestamp", ">", new Date(options.notificationViewedAt))
//         .get();
//     } else {
//       notificationRef = await firestoreOperationsDb
//         .collection(`notifications`)
//         .where("userId", "==", options.userId)
//         .get();
//     }

//     cb(null, {
//       size: notificationRef.size,
//     });
//   } catch (error) {
//     console.log("error fetching records", error);
//     cb(error.message, null);
//   }
// }

// export async function saveDeviceTokens(userId, token, operation, cb) {
//   try {
//     const userRef = firestoreOperationsDb.collection("users").doc(userId);
//     let userData = await userRef.get();
//     userData = userData.data();
//     let tokens = userData.tokens || [];
//     if (operation === "add") {
//       let index = tokens.findIndex((d) => d.deviceToken === token);
//       if (index === -1) {
//         tokens.push({
//           deviceType: "web",
//           deviceToken: token,
//         });
//       }
//     } else {
//       if (tokens.length > 0) {
//         let index = tokens.findIndex((d) => d.deviceToken === token);
//         if (index !== -1) {
//           tokens.splice(index, 1);
//         }
//       }
//     }
//     await userRef.update({ tokens });
//     cb(null, {
//       success: true,
//     });
//   } catch (error) {
//     console.log("error fetching records", error);
//     cb(error.message, null);
//   }
// }

// export async function updateNotificationViewedAt(options, cb) {
//   try {
//     let date = moment().utc().format();
//     const userRef = firestoreOperationsDb
//       .collection("users")
//       .doc(options.userId);
//     await userRef.update({ notificationViewedAt: date });

//     cb(null, {
//       date,
//     });
//   } catch (error) {
//     console.log("error fetching records", error);
//     cb(error.message, null);
//   }
// }

// export async function addPatients(cb) {
//   let planList = [];
//   const typeRef = firestoreOperationsDb.collection("patients");
//   typeRef
//     .get()
//     .then(async function (patientsData) {
//       await patientsData.forEach(async function (doc) {
//         let plan = doc.data();
//         // plan.planId = doc.id;
//         plan.objectID = doc.id;
//         planList.push(plan);
//       });
//       // cb(null, planList);
//       console.log("planList", planList)
//       patientIndex
//         .saveObjects(planList)
//         .then((patient) => {
//           // done
//           console.log("Patient uploaede scuessfully")
//         })
//         .catch((err) => {
//           console.log("[Error]", err)
//         })
//     })
//     .catch(function (error) {
//       console.log("error:", error);
//       cb(error.message, null);
//     });
// }

// export async function uploadMed(cb) {
//    let algoliaRecords = []
//     console.log("Data", data.length)
//     data.forEach(async (document) => {
//       const record = {
//         code: document.code,
//         system: document.system,
//         display: document.display,
//         value: document.display,
//         label: document.display,
//       };
//       algoliaRecords.push(record);
//     })

//     let chunks = _.chunk(algoliaRecords, 5000);
//         console.log("algoliaRecords:", algoliaRecords.length);

//     await chunks.forEach(async (val) => {
//       await index.saveObjects(val, {
//         autoGenerateObjectIDIfNotExist: true
//       }, (error, content) => {
//         if (error) {
//           console.log("error", error)
//         }
//       });
//     });
// }
// addPatients()
// uploadMed()
// export async function uploadBadge(cb) {
//   try {
//     const batch = firestoreMasterDb.batch();
//     await badges.forEach((ele, index) => {
//       if(!ele.isFirstTask) ele.isFirstTask = false
//       let ref = firestoreMasterDb.collection("badges").doc();
//       batch.set(ref, ele);
//     });
//     await batch.commit();
//     console.log("All Badges aaded sucesfully")
//   } catch(err) {
//     console.log("[Error importing badges]", err)
//   }
// }
// uploadBadge()

export async function getUpcomingConsult(patientId, cb) {
  const typeRef = firestoreOperationsDb.collection("tasks");
  let dateToMatch = moment().endOf("day").toDate();
  dateToMatch = moment("07-08-2022", "DD-MM-YYYY").endOf("day").toDate();
  //console.log(dateToMatch);
  typeRef
    .orderBy("dueDateTimeStamp", "desc")
    .where("isCompleted", "==", false)
    .where("isDeleted", "==", false)
    .where("type", "==", "followUp")
    .where("patientId", "==", patientId)
    .where("dueDateTimeStamp", ">", dateToMatch)
    .limit(1)
    .get()
    .then(async function (tasks) {
      if (!tasks.empty) {
        await tasks.forEach(async function (doc) {
          let task = doc.data();
          cb(task);
        });
      } else {
        cb(null);
      }
    })
    .catch(function (error) {
      //console.log("error:", error);
      cb(null);
    });
}
