import axios from 'axios'
import appConstants from '../appConstants';

const instance = axios.create({
    // change when deploy in production 
    baseURL: `${appConstants.firebaseFunctionsUrl}`,
})

instance.interceptors.request.use(
    async (config) => {
        config.headers.Accept = "application/json";
        config.headers["Access-Control-Allow-Origin"] = "*"
        config.headers["Content-Type"] = "application/json";
        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }

)

export default instance
